.country-and-currency-root{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
}

.country-form-container{
    width: 60%;
}

.country-currency-notice{
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 25px;
}
.country-currency-notice h3{
    color: maroon;
    font-size: 1.4em;
    font-weight: bold;
}
.country-currency-notice p{
    color: #666;
    font-size: 1.1em;
    margin-top: 5px;
}

@media (max-width: 480px) {
    .country-form-container{
        width: 90%;
    }
}