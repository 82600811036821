.error-page-container {
  height: 100vh;
  width: 100%;
  background: #daceda;
  display: flex;
  align-items: center;
  justify-content: center;

  overflow: hidden !important;
}

.more-info {
  color: #833e83;
  text-decoration: none;
}

.close-error-log {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 1rem;
  margin-right: 1rem;
  color: #833e83;
  padding: 0.5rem;
  background-color: white;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  padding: 0.8rem;
}

.close-error-log:hover {
  box-shadow: var(--elevate-1);
  transition: 0.2s ease-out;
}
.error-log {
  position: absolute;
  background-color: #833e83;
  color: white;

  left: 0;
  width: 20vw;
  height: 100vh;

  display: grid;
  place-items: center;

  animation: slide-right 0.1s linear;
}

.error-report {
  padding: 2rem;
}

#oops:target {
  display: grid;
}

#oops {
  display: none;
}

@media screen and (min-width: 560px) {
  .error-log {
    left: 0;
    width: 20vw;
    height: 100vh;

    animation: slide-right 0.1s linear;
  }
}

@keyframes slide-right {
  from {
    left: -20vw;
  }
  to {
    left: 0vw;
  }
}
