.login-btn-container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 25px;
    width: 10vw;
    margin-top: 3rem;
    border-radius: 7px;
    background-color: var(--app-theme);
    color: white;
    cursor: pointer;
    border: 1px solid var(--app-theme);
}
.login-btn-container:hover{
    color: var(--app-theme);
    background-color: white;
}

@media (max-width: 480px) {
    .login-btn-container{
    width: 50vw;
}
}