@keyframes scale-and-fade-in {
  from {
    opacity: 0;
    transform: scale(0.9) translateY(70px);
  }
  to {
    opacity: 1;
    transform: scale(1) translateY(0px);
  }
}

.scale-and-fade-in {
  opacity: 0;
  animation-delay: 0.3s;
  animation: scale-and-fade-in 0.6s forwards;
}


