.modal-root {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 100;
  background-color: rgba(13, 14, 15, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: absolute; */
  inset: 0;
  opacity: 1;
}

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.modal-header {
  display: flex;
  justify-content: space-between;
  background-color: purple;
  color: white;
  padding: 13px 15px;
}

.modal-container {
  background-color: white;
  width: 25vw;

  /* height: 10rem; */
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  /* margin-top: -15%; */
  opacity: 0;
  animation-delay: 0.4s;
  animation: scaleIn 0.3s linear forwards;
}

.modal-container-small {
  width: 25vw;
  min-height: 15vw;
}

.modal-container-medium {
  width: 45vw;
  min-height: 25vw;
}

.modal-container-large {
  width: 75vw;
  min-height: 45vw;
}

.modal-content {
  /* padding: 1rem; */
  flex: 1 0 0;
  max-height: 70vh;
  overflow-y: auto;
}

.modal-btns {
  display: flex;
  justify-content: flex-end;
  padding: 8px 5px;
  background: #e8e8e8;
}

.modal-close-btn {
  cursor: pointer;
}

/* TODO: When responsiveness becomes prio */ 
 @media screen and (max-width: 480px) {
.modal-container{
  width: 90%;

}
}
