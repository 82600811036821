table {
  border-collapse: collapse;
  width: 100%;
}

/* table onload animation */
table td {
  /* table.animate td { */
  opacity: 0;
  transform: translateY(-1rem);
  animation: animate-table 0.5s ease-in-out forwards;
}

table td:nth-child(even) {
  animation-delay: 0.5s;
}

@keyframes animate-table {
  from {
    opacity: 0;
    transform: translateY(-1rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
/* end of table onload animation */

th {
  text-align: left;
  padding: 0.5rem 1rem;
  color: #444;
  text-transform: capitalize;
}

td {
  padding: 0.7rem 1rem;
  transition: all 0.5 linear;
}

table.light td,
table.light th {
  border-bottom: 0.001rem solid #e7e7e7;
}

table.checkered tbody tr:nth-child(even) {
  background-color: var(--app-table-checkered);
}

table tbody tr:hover {
  cursor: pointer;
  background-color: hsl(0deg 0% 96%) !important;
}

/* table row animation on hover */
table.hover-animation tbody tr:hover {
  /* background-color: hsl(0deg 0% 96%) !important; */
  /* cursor: pointer; */
  transition: transform 0.1s;
  animation: animate-table 0.05s linear forwards;
  position: relative;
}

/* this fixes the flicking behavior on hover */
table.hover-animation tbody tr:hover:after {
  content: " ";
  position: absolute;
  left: 0;
  width: 100%;
  height: 150%;
}
