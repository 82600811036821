.step-status{
  border-radius: 100%;
  border: 1px solid #d6dbdf;
  width: 15px;
  height: 15px;
  padding: 3px;
  text-align: center;
  margin-bottom: 1.2rem;
  cursor: pointer;
  color: #808b96;
  margin-right: 10px;
}

.stepper-header{
    display: flex;
    width: 90%;
    margin:auto;
}

.stepper-root{
    width: 100%;
    margin-top: 2rem;
}
.stepper-root-vertical{
    display:flex;
    width: 100%;
    margin-top: 2rem;
}

.components-container{
    width: 80%;
      margin: auto;
      margin-top: 3rem;
    position: relative;
    margin-bottom: 2rem;
}
.components-container-vertical{
    width: 70%;
    position: relative;
}
.stepper-headers-container{
    width:100%;
    display: flex !important;
}
.stepper-headers-container-vertical{
    width:20%;
}

.stepper-navigation-btns{
    position: fixed;
    bottom: 20px;
    display: flex;
    justify-content: space-between;
    width: 60%;
    margin: auto;
}

.left{
    position: absolute;
     left: 10px;
      bottom: 23px;
}
.right{
     position: absolute;
     right: 10px;
      bottom: 23px;
}
.navigation-btn{
    padding: 10px 25px !important;
    display: flex;
    background-color: white;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.navigation-btn:hover{
background-color: #F4D03F;
}
.navigation-btn svg{
margin-right: 10px;
margin-bottom: 5px;
}

.divider-line {
  height: 50px;
  background-color: grey;
  position: absolute;
  width: 1px;
  left:25px;
  top: -54px;
}

.active{
    background-color: #e51670;
}
.completed{
    background-color: #1d9d39;
}

.step-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 23%;
  position: relative;
}
.step-number {
  border-radius: 100%;
  border: 1px solid #d6dbdf;
  width: 50px;
  height: 50px;
  padding: 3px;
  text-align: center;
  margin-bottom: 1.2rem;
  cursor: pointer;
  color: #808b96;
}

.divider-line-horizontal {
  height: 1px;
  background-color: #d6dbdf;
  position: absolute;
  width: 55%;
  top: 26%;
  left: 70%;
  
}

.step-number-active {
  background: tomato !important;
  color: white;
  border: none;
}
.step-number-completed {
  background: seagreen;
  color: white;
  border: none;
}
.step-title-active {
  color: #85c1e9 !important;
  font-weight: bold;
}
.step-title {
  color: gray;
}

.center-me{
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 480px) {
.navigation-btn {
    padding: 10px 16px !important;
}
.stepper-navigation-btns{
    width: 80%;
}
.components-container{
    width: 100%;
}
.step-number {
  width: 30px;
  height: 30px;
}
.step-number svg{
  font-size: 1rem;
}
.stepper-headers-container{
    display: flex !important;
    justify-content: space-evenly;
}

.divider-line-horizontal {
    top: 20%;
    left: 100%;
}

}