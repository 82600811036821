.title-box {
  flex-direction: column;
  align-items: flex-start;
  padding-left: 25px;
}

.title-box h1 {
  color: var(--app-theme-lighter);
}
.title-box p {
  color: grey;
  margin-top: 8px;
}
