.enterprise-switch-wrapper {
  position: absolute;
  z-index: 1000000;
  width: 100vw;
  height: 100vh;
  overflow-y: hidden;
  animation: slideIn 0.5s ease-in-out;
}

.enterprise-switch-container {
  width: 25%;
  background-color: white;
  border-radius: 6px;
}
.on-shrink {
  position: absolute;
  left: 60px;
}

.item-divider {
  width: 85%;
  height: 1px;
  margin: auto;
  background-color: #d1cfcf;
  margin-top: 10px;
}

#current-enterprise-det-container {
  display: flex;
  width: 80%;
  margin: auto;
  /* justify-content: space-between; */
}

#curr-text-container {
  margin-top: 10px;
  margin-left: 10px;
}
#ent-img {
  border-radius: 100%;
}
#curr-text-container p {
  color: var(--app-theme);
  font-size: 1.4rem;
  font-weight: bold;
}
#curr-text-container span {
  color: grey;
  font-size: 0.9rem;
}

#other-ent-item-container {
  display: flex;
  padding-top: 10px;
  padding-left: 10px;
  padding-bottom: 10px;
}

#other-ent-item-container:hover {
  cursor: pointer;
  background-color: var(--app-accent-pink);
}
#other-ent-item-container:hover > div p {
  color: white !important;
}
.switch-btn {
  margin-top: 10px;
}
#other-ent-item-container p {
  color: var(--app-theme);
  font-size: 1.1rem;
  /* margin-top: 10px; */
}
#other-ent-item-wrapper {
  padding-left: 15px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  max-height: 20vh;
  overflow: auto;
}


@keyframes slideIn {
  from {
    transform: translateY(1vh);
  }
  to {
    transform: translateY(0);
  }
}

.round-profile-icon {
  height: 42px;
  width: 42px;
  border-radius: 100%;
  padding: 1px;
  border: solid 2px var(--app-accent-pink);
  object-fit: cover;
  margin-left: 15px;
}