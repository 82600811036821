.report-card-root{
    padding: 15px 20px;
    border-radius: 10px;
    margin-bottom: 10px;
}
#report-card-logo-and-name-container{
    display:flex;
}

#report-card-logo-container img{
width: 60px;
height: 60px;
border-radius: 100%;
object-position: center;
object-fit: cover;
border: 3px solid var(--app-accent-pink);

}
#report-card-logo-container {
    margin-right: 10px;
}

#report-card-opp-det p{
    margin-top: 5px;
}

#report-card-title-container{
    /* padding: 0px 20px; */
    margin-top: 20px;
}

.report-card-body-container{
    /* padding: 5px 20px; */
    margin-bottom: 1rem;
}
.report-card-body-container p{
    text-align: justify;
}

#report-card-footer-container{
    display: flex;
justify-content: flex-end;
margin-top: 10px;
}
.comment-btn-container{
    float: right;
    padding: 15px 20px;
    width: fit-content;
    border-radius: 50px;
    margin-right: 20px;
    cursor: pointer;
    border: 2px solid whitesmoke;
}
.comment-btn-container:hover{
background-color: #F4D03F;
/* color: white; */
}
.comment-btn-container span{
margin-left: 10px;
}
.comment-btn-container svg{
/* color: whitesmoke; */
font-size: 1rem;
color: grey;
}
#report-card-body-images img{
    width:100%;
    object-fit: cover;
    height:45vh;
    border-radius:10px;
}
#report-card-body-images video{
    width:100%;
    object-position: center;
    object-fit: fill;
    height:55vh;
    border-radius:10px;
    border:  1px solid #ccc;
}
#report-card-body-images{
 margin-top: 20px;
}

.divider{
    width: 100%;
    height: 1px;
    background-color: rgb(214, 213, 213);
}
.report-card-body-container{
     width: 90%;
  
     cursor: pointer
}
.report-card-body{
   margin-left:4.6rem;
}

@media (max-width: 480px) {
#report-card-logo-container img{
width: 39px;
height: 39px;

}
#report-card-body-images img{
    height: 100%;
}
.report-card-body-container{
     margin-left:0rem;
     width: 100%;
}
}