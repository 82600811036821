@import url(https://fonts.googleapis.com/css?family=Google+Sans:200,300,400,400i,500,600,700,900);
@import url(https://fonts.googleapis.com/css?family=Google+Sans:200,300,400,400i,500,600,700,900);
@import url(https://fonts.googleapis.com/css?family=Roboto+Slab:400,700);
:root {
  --elevate-float: 0 1px 0px 0 rgba(0, 0, 0, -1.84),
    0 2px 10px 0 rgba(0, 0, 0, 0.1);
  --elevate-half: 0 1px 0px 0 rgb(0 0 0 / 0%), 0 2px 4px 0 rgb(0 0 0 / 3%);
  --elevate-1: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  --elevate-1-half: 0 5px 11px 0 rgba(0, 0, 0, 0.18),
    0 4px 15px 0 rgba(0, 0, 0, 0.15);
  --elevate-2: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  --elevate-3: 0 12px 15px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
  --elevate-4: 0 16px 28px 0 rgba(0, 0, 0, 0.22),
    0 25px 55px 0 rgba(0, 0, 0, 0.21);
  --elevate-5: 0 27px 24px 0 rgba(0, 0, 0, 0.2),
    0 40px 77px 0 rgba(0, 0, 0, 0.22);

  --elevate-float-2: 0px 4px 42px -12px rgba(0, 0, 0, 0.09);
}
.elevate-float {
  box-shadow: 0 1px 0px 0 rgba(0, 0, 0, -1.84),
    0 2px 10px 0 rgba(0, 0, 0, 0.1);
  box-shadow: var(--elevate-float);
  -webkit-box-shadow: 0 1px 0px 0 rgba(0, 0, 0, -1.84),
    0 2px 10px 0 rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: var(--elevate-float);
}
.elevate-half {
  box-shadow: 0 1px 0px 0 rgb(0 0 0 / 0%), 0 2px 4px 0 rgb(0 0 0 / 3%);
  box-shadow: var(--elevate-half);
  -webkit-box-shadow: 0 1px 0px 0 rgb(0 0 0 / 0%), 0 2px 4px 0 rgb(0 0 0 / 3%);
  -webkit-box-shadow: var(--elevate-half);
}
.elevate-1 {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: var(--elevate-1);
}
.elevate-1-half {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18),
    0 4px 15px 0 rgba(0, 0, 0, 0.15);
  box-shadow: var(--elevate-1-half);
}
.elevate-2 {
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  box-shadow: var(--elevate-2);
}

.elevate-3 {
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
  box-shadow: var(--elevate-3);
}

.elevate-4 {
  box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22),
    0 25px 55px 0 rgba(0, 0, 0, 0.21);
  box-shadow: var(--elevate-4);
}

.elevate-5 {
  box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2),
    0 40px 77px 0 rgba(0, 0, 0, 0.22);
  box-shadow: var(--elevate-5);
}
.elevate-float-2 {
  box-shadow: 0px 4px 42px -12px rgba(0, 0, 0, 0.09);
  box-shadow: var(--elevate-float-2);
}

@media only screen and (max-width: 767px) {
  .vanish-on-phone {
    display: none !important;
  }

  .footer-bottom-panel {
    bottom: -70px;
  }

  .footer-bottom-panel p {
    font-size: 0.8rem;
    text-align: center;
  }

  .row {
    margin: 0px !important;
  }

  .footer-link {
    display: inline;
    margin-right: 4px;
    font-size: 0.8rem;
  }

  .footer-column-title {
    font-size: 0.9rem;
  }

  .footer-container {
    height: 600px;
  }
}

@media only screen and (min-width: 768px) {
  .vanish-on-pc {
    display: none !important;
  }

 
}

@-webkit-keyframes scale-and-fade-in {
  from {
    opacity: 0;
    -webkit-transform: scale(0.9) translateY(70px);
            transform: scale(0.9) translateY(70px);
  }
  to {
    opacity: 1;
    -webkit-transform: scale(1) translateY(0px);
            transform: scale(1) translateY(0px);
  }
}

@keyframes scale-and-fade-in {
  from {
    opacity: 0;
    -webkit-transform: scale(0.9) translateY(70px);
            transform: scale(0.9) translateY(70px);
  }
  to {
    opacity: 1;
    -webkit-transform: scale(1) translateY(0px);
            transform: scale(1) translateY(0px);
  }
}

.scale-and-fade-in {
  opacity: 0;
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
  -webkit-animation: scale-and-fade-in 0.6s forwards;
          animation: scale-and-fade-in 0.6s forwards;
}



:root {
  --default-font-family: "Google Sans", sans-serif;
}

html {
  font-family: "Google Sans", sans-serif;
  font-family: var(--default-font-family);
}
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100vh;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
.put-me-inline {
  display: inline-block;
}

.everyday-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.text-toggler {
  color: var(--app-theme-success) !important;
  margin-left: 6px;
  cursor: pointer;
}

.text-toggler:hover {
  color: var(--app-accent-pink) !important;
  transition: 0.2s ease-out;
}

.round-action-btns {
  background-color: var(--action-color);
  margin-left: 15px;
}

.error-page-container {
  height: 100vh;
  width: 100%;
  background: #daceda;
  display: flex;
  align-items: center;
  justify-content: center;

  overflow: hidden !important;
}

.more-info {
  color: #833e83;
  text-decoration: none;
}

.close-error-log {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 1rem;
  margin-right: 1rem;
  color: #833e83;
  padding: 0.5rem;
  background-color: white;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  padding: 0.8rem;
}

.close-error-log:hover {
  box-shadow: var(--elevate-1);
  transition: 0.2s ease-out;
}
.error-log {
  position: absolute;
  background-color: #833e83;
  color: white;

  left: 0;
  width: 20vw;
  height: 100vh;

  display: grid;
  place-items: center;

  -webkit-animation: slide-right 0.1s linear;

          animation: slide-right 0.1s linear;
}

.error-report {
  padding: 2rem;
}

#oops:target {
  display: grid;
}

#oops {
  display: none;
}

@media screen and (min-width: 560px) {
  .error-log {
    left: 0;
    width: 20vw;
    height: 100vh;

    -webkit-animation: slide-right 0.1s linear;

            animation: slide-right 0.1s linear;
  }
}

@-webkit-keyframes slide-right {
  from {
    left: -20vw;
  }
  to {
    left: 0vw;
  }
}

@keyframes slide-right {
  from {
    left: -20vw;
  }
  to {
    left: 0vw;
  }
}

.title-box {
  flex-direction: column;
  align-items: flex-start;
  padding-left: 25px;
}

.title-box h1 {
  color: var(--app-theme-lighter);
}
.title-box p {
  color: grey;
  margin-top: 8px;
}

.login-btn-container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 25px;
    width: 10vw;
    margin-top: 3rem;
    border-radius: 7px;
    background-color: var(--app-theme);
    color: white;
    cursor: pointer;
    border: 1px solid var(--app-theme);
}
.login-btn-container:hover{
    color: var(--app-theme);
    background-color: white;
}

@media (max-width: 480px) {
    .login-btn-container{
    width: 50vw;
}
}
:root {
  --toast-error-color: #ffe2e2;
  --toast-success-color: #259c23;
}

.toast-wrapper {
  position: fixed;
  bottom: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
}

.toast-container {
  /* position: fixed; */
  padding-right: 20px;
  color: white;
  /* max-width: 40%; */
  width: 60%;
  margin: auto;
  display: flex;
  color: grey;
  background-color: var(--toast-back-color);
  border-radius: 4px;
  border: solid 0px;
  border-left-width: 8px;
  border-left-color: var(--toast-border-color);
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  color: white;
  padding: 20px 20px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.success {
  background-color: #49c994;
}

.failed {
  background-color: tomato;
}
.icon {
  font-size: 1.5rem;
}

.message-container {
  display: flex;
  /* justify-content: center; */
  flex: 10 1;
  align-items: center;
  text-align: justify;

  /* margin-right: 2rem; */
  padding: 22px;
}

.message-container div {
  color: var(--toast-text-color);
}

.close-btn-container {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 5px 15px;
  margin-left: 2rem;
}

.close {
  display: none !important;
}

/* .right {
  right: 10px;
  top: 100px;
  animation: rightMove 0.5s ease-out 0s forwards;
}
.top {
  top: 0px;
  transform: translate(50%, 100%);
  animation: topMove 0.5s ease-out 0s forwards;
}
.bottom {
  bottom: 30px;
  transform: translate(50%, 10%);
  animation: bottomMove 0.5s ease-out 0s forwards;
}

@keyframes bottomMove {
  from {
    bottom: -60px;
  }
  to {
    bottom: 30px;
    transform: translate(50%, 10%);
  }
}
@keyframes topMove {
  from {
    top: -60px;
  }
  to {
    top: 0px;
    transform: translate(50%, 100%);
  }
}

@keyframes rightMove {
  from {
    top: 100px;
    right: -600px;
  }
  to {
    right: 10px;
    top: 100px;
  }
} */

.show-from-bottom {
  opacity: 0;
  /* animation-delay: 0.6; */
  -webkit-animation: show-from-bottom .4s forwards;
          animation: show-from-bottom .4s forwards;
}
@-webkit-keyframes show-from-bottom {
  from {
    opacity: 0;
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}
@keyframes show-from-bottom {
  from {
    opacity: 0;
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

.modal-root {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 100;
  background-color: rgba(13, 14, 15, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: absolute; */
  inset: 0;
  opacity: 1;
}

@-webkit-keyframes scaleIn {
  from {
    opacity: 0;
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes scaleIn {
  from {
    opacity: 0;
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.modal-header {
  display: flex;
  justify-content: space-between;
  background-color: purple;
  color: white;
  padding: 13px 15px;
}

.modal-container {
  background-color: white;
  width: 25vw;

  /* height: 10rem; */
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  /* margin-top: -15%; */
  opacity: 0;
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
  -webkit-animation: scaleIn 0.3s linear forwards;
          animation: scaleIn 0.3s linear forwards;
}

.modal-container-small {
  width: 25vw;
  min-height: 15vw;
}

.modal-container-medium {
  width: 45vw;
  min-height: 25vw;
}

.modal-container-large {
  width: 75vw;
  min-height: 45vw;
}

.modal-content {
  /* padding: 1rem; */
  flex: 1 0;
  max-height: 70vh;
  overflow-y: auto;
}

.modal-btns {
  display: flex;
  justify-content: flex-end;
  padding: 8px 5px;
  background: #e8e8e8;
}

.modal-close-btn {
  cursor: pointer;
}

/* TODO: When responsiveness becomes prio */ 
 @media screen and (max-width: 480px) {
.modal-container{
  width: 90%;

}
}

:root {
  --app-theme: #3f0f3f;
  /* --app-theme-lighter: #531a6b; */
  --app-table-checkered: hsl(351deg 100% 98%);
  --app-theme-lighter: #4c164c;
  --app-theme-danger: rgb(207, 85, 85);
  --app-theme-hover: #9d41c3;
  --app-theme-active: #531a6b;
  --app-theme-success: #1d9d39;
  --app-theme-grey: #cdcdcd;
  --app-accent-pink: #e51670;
  --app-accent-orange: #fc8661;
  --app-money-green: #8bac2d;
  --app-money-green-lighter: #b8cf75;
  --app-trans-blue: #f1fbff;
  --app-trans-yellow: #fefdf2;
  --app-trans-pink: #fef4ff;
  --app-trans-purple: #fdf0ff;
  --app-settled-background: #d7e5d6;
  --app-deny-background: #e5d6d6;
  --app-clear-background: #d6e0e5;
  --app-clear-solid: #1d8e9d;
  --app-selling-background: #e5e2d6;
  --app-selling-solid: #cd792c;
  --app-negotiation-background: #dcd6e5;
  --app-negotiation-solid: #6d23b7;
  --app-placeholder-grey: #a2a2a2;
}

:root {
  --small-button-padding: 10px 15px;
  --medium-button-padding: 11px 20px;
  --default-button-padding: 10px 25px;
  --large-button-padding: 12px 35px;

  --small-font-size: 0.8rem;
  --medium-font-size: 1rem;
  --default-font-size: 0.9rem;
  --large-font-size: 1.1rem;
}

button {
  outline: 0;
  border: 0;
  color: white;
  font-family: sans-serif;
  font-size: 1rem;
}

.button {
  padding: 11px 20px;
  padding: var(--medium-button-padding);
  background: purple;
  color: White;
  border-radius: 4px;
  box-shadow: 0 0 0;
  border: 0px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  text-align: left;
}

.button:hover {
  background-color: darkslateblue !important;
  border: 0.1rem solid darkslateblue !important;
  color: white !important;
}

button[class*="danger"]:active {
  -webkit-animation: button-active 0.1s ease-in-out;
          animation: button-active 0.1s ease-in-out;
}

button[class*="themed"]:active {
  -webkit-animation: button-active 0.1s ease-in-out;
          animation: button-active 0.1s ease-in-out;
}

button[class*="success"]:active {
  -webkit-animation: button-active 0.1s ease-in-out;
          animation: button-active 0.1s ease-in-out;
}

@-webkit-keyframes button-active {
  from {
    box-shadow: 0 0 0.05rem 0.1rem hsla(248, 39%, 39%, 0.5);
  }
  to {
    box-shadow: 0 0 0.5rem 1rem hsla(248, 39%, 39%, 0.1);
  }
}

@keyframes button-active {
  from {
    box-shadow: 0 0 0.05rem 0.1rem hsla(248, 39%, 39%, 0.5);
  }
  to {
    box-shadow: 0 0 0.5rem 1rem hsla(248, 39%, 39%, 0.1);
  }
}

@-webkit-keyframes button-active-danger {
  from {
    box-shadow: 0 0 0.05rem 0.1rem hsla(2, 54%, 48%, 0.5);
  }
  to {
    box-shadow: 0 0 0.5rem 1rem hsla(2, 54%, 48%, 0.1);
  }
}

@keyframes button-active-danger {
  from {
    box-shadow: 0 0 0.05rem 0.1rem hsla(2, 54%, 48%, 0.5);
  }
  to {
    box-shadow: 0 0 0.5rem 1rem hsla(2, 54%, 48%, 0.1);
  }
}

@-webkit-keyframes button-active-success {
  from {
    box-shadow: 0 0 0.05rem 0.1rem hsla(133, 69%, 36%, 0.5);
  }
  to {
    box-shadow: 0 0 0.5rem 1rem hsla(133, 69%, 36%, 0.1);
  }
}

@keyframes button-active-success {
  from {
    box-shadow: 0 0 0.05rem 0.1rem hsla(133, 69%, 36%, 0.5);
  }
  to {
    box-shadow: 0 0 0.5rem 1rem hsla(133, 69%, 36%, 0.1);
  }
}

@-webkit-keyframes button-active-themed {
  from {
    box-shadow: 0 0 0.05rem 0.1rem hsla(300, 62%, 15%, 0.5);
  }
  to {
    box-shadow: 0 0 0.5rem 1rem hsla(300, 62%, 15%, 0.1);
  }
}

@keyframes button-active-themed {
  from {
    box-shadow: 0 0 0.05rem 0.1rem hsla(300, 62%, 15%, 0.5);
  }
  to {
    box-shadow: 0 0 0.5rem 1rem hsla(300, 62%, 15%, 0.1);
  }
}

.button[class*="small"] {
  font-size: 0.8rem;
  font-size: var(--small-font-size);
  padding: 10px 15px;
  padding: var(--small-button-padding);
}
.button[class*="medium"] {
  font-size: 1rem;
  font-size: var(--medium-font-size);
  padding: 11px 20px;
  padding: var(--medium-button-padding);
}
.button[class*="large"] {
  font-size: 1.1rem;
  font-size: var(--large-font-size);
  padding: 12px 35px;
  padding: var(--large-button-padding);
}

.button[class*="themed"] {
  background-color: var(--app-theme);
  border: 0.1rem solid var(--app-theme);
}
.button[class*="success"] {
  background-color: var(--app-theme-success);
  border: 0.1rem solid var(--app-theme-success);
}
.button[class*="danger"] {
  background-color: var(--app-theme-danger);
  border: 0.1rem solid var(--app-theme-danger);
}

.button[class*="hollow"] {
  background-color: transparent;
}

.button[class*="hollow-themed"] {
  color: var(--app-theme);
}

.button[class*="hollow-success"] {
  color: var(--app-theme-success);
}

.button[class*="hollow-danger"] {
  color: var(--app-theme-danger);
}

.button[class*="default"] {
  background-color: purple;
  border: 0.1rem solid purple;
}

/* icon */
.button-icon-left {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.button-icon-right {
  display: flex;
  flex-flow: row-reverse;
  justify-content: space-between;
}

.button-icon-left svg {
  margin-right: 1rem;
}

.button-icon-right svg {
  margin-left: 1rem;
}

.test {
  width: 10rem;
}

.spinner {
  border-radius: 50%;
  -webkit-animation: spin 0.8s linear infinite;
          animation: spin 0.8s linear infinite;
}

.spinner.OneHalfCircleType,
.spinner.TwoHalfCirclesType {
  border-color: transparent;
}

.spinner.TransparentCircleType {
  border-color: #eee;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: fixed;
  width: 100vw;
  z-index: 1000;
  top: 0;
  left: 0;
  background: #f5eaff;
  opacity: 0;
  -webkit-animation: 0.4s load-in forwards;
          animation: 0.4s load-in forwards;
}

@-webkit-keyframes load-in {
  from {
    opacity: 0;
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes load-in {
  from {
    opacity: 0;
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.response-wrapper{
    padding: 20px 40px;
   border-top-right-radius: 0px;
   border-top-left-radius: 60px;
   border-bottom-right-radius: 60px;
   border-bottom-left-radius: 0px;
}


#response-btns-container{
    display: flex;
    justify-content: space-evenly;
}

#response-intro-container{
    margin-top: 1.6rem;
    margin-bottom: 2rem;
}

#res-about-container{
      margin-bottom: 3rem;
}
#res-about-container p{
     text-align: justify;
}
#res-name-section{
    margin-top: 2rem;
}
.k-title {
  color: var(--app-theme-lighter);
  margin-bottom: 12px;
}

.i-am-money {
  color: var(--toast-success-color) !important;
  font-weight: bold;
}

.all-pages-root-wrapper {
  margin: auto;
  margin-top: 1rem;
}
/* ======= end of Generic css ======== */
#user-dash-intro {
  display: flex;
  justify-content: space-between;
}
#intro-stats-container {
  width: 60%;
  display: flex;
  justify-content: flex-end;
}

#intro-txt-container {
  width: 40%;
}
#intro-txt-container p {
  font-size: 2rem;
  font-weight: bold;
}
#intro-txt-container span {
  font-size: 0.9rem;
  color: grey;
}

#intro-stats-container div  {
font-size: 2.5rem;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
}
#stat-item-container p {
  font-size: 3rem;
  font-weight: bold;
  color: var(--app-theme);
}
#stat-item-container span {
  font-size: 0.9rem;
  font-weight: bold;
  color: var(--app-theme);
  text-align: right !important;
}
.move-down-small {
      margin-right: 20px;
}

.profit-items {
  color: var(--toast-success-color) !important;
}
/* ============================ end of intro css ============= */

.dash-card-container {
  padding: 15px;
  border-radius: 20px;
  /* width: 95%; */
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
}

.dash-card-item {
  padding: 10px;
  display: flex;
}

.dash-card-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--app-accent-pink);
  border-radius: 10px;
  height: 55px;
  width: 55px;
  margin-right: 20px;
}
.dash-card-icon-container svg {
  color: white;
  font-size: 2rem;
}
.dash-card-txt-conatainer p {
  font-size: 1rem;
  color: grey;
}
.dash-card-txt-conatainer span {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--app-theme);
}

/* ========== end of cards css ================ */
.dashboard-investments-invites-container {
  margin-top: 2rem;
}
.inventments-lists {
  height: -webkit-max-content;
  height: max-content;
  /* width: 95%; */
  margin-bottom: 15px;
}
.invitation-list {
  /* width: 95%; */
  height: -webkit-max-content;
  height: max-content;
}
.dash-invites-container {
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 1rem;
}
.top-investors-container {
  padding: 20px;
  border-radius: 10px;
}

.dashboard-transaction-bar-container {
  margin-top: 1.5rem;
}
.right-of-title-box {
  flex-direction: column;
  align-items: flex-end;
  padding-right: 70px;
}

.right-of-title-box h6 {
  color: var(--app-money-green);
}

.right-of-title-box h1 {
  font-size: 3rem;
  color: var(--app-money-green);
}
.right-of-title-box h2 {
  color: var(--app-theme-success);
}

.accept-txt {
  padding: 10px 15px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.accept:hover {
  background-color: var(--app-theme-success);
  color: white;
}
.reject:hover {
  background-color: tomato;
  color: white;
}
.view:hover {
  background-color: var(--app-accent-pink);
  color: white;
}

#actions {
  display: flex;
}
.accept {
  margin-right: 7px;
}
.view {
  margin-right: 7px;
}

.set-payment-pref-container {
  position: relative;
  padding: 20px;
  /* width: 95%; */
  border-radius: 10px;
  margin-top: 30px;
}

.set-payment-close-btn {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 40px;
  width: 40px;
  cursor: pointer;
  color: tomato;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.set-payment-close-btn:hover {
  background-color: tomato;
  color: white;
}

#info-icon-container svg {
  margin-right: 15px;
  font-size: 2rem;
  color: maroon;
}

#notification-body-container span {
  color: maroon;
  font-weight: bold;
  font-size: 1.2rem;
}
#notification-body-container p {
  margin-top: 5px;
}
#notification-body-container p a{
  text-decoration: none;
  font-weight: bold;
}


@media (max-width: 480px) {
  #intro-txt-container p {
  font-size: 1rem;
}
.dash-card-icon-container {
    height: 45px;
    width: 45px;
    margin-right: 5px;
}
.dash-card-icon-container svg {
  font-size: 1rem;
}
.dash-card-txt-conatainer span {
    font-size: 1rem;
}
.dash-card-container {
    width: 100%;
    display: block;
    padding: 0;
}
.mb{
  margin-bottom: 10px;
}

#intro-stats-container div svg {
    display: none;
    
}
#intro-stats-container, #user-dash-intro {
    display: block;
}
#intro-stats-container {
    width: 100%;
    position: relative;
}
#stat-item-container p {
    font-size: 1.4rem;
}
#intro-txt-container {
    width: 100%;
}
.dash-card-item {
border-radius: 10px;
}
#intro-stats-container div {
    font-size: 1.5rem;
    display: block;
    margin-bottom: 20px;
}
}
table {
  border-collapse: collapse;
  width: 100%;
}

/* table onload animation */
table td {
  /* table.animate td { */
  opacity: 0;
  -webkit-transform: translateY(-1rem);
          transform: translateY(-1rem);
  -webkit-animation: animate-table 0.5s ease-in-out forwards;
          animation: animate-table 0.5s ease-in-out forwards;
}

table td:nth-child(even) {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}

@-webkit-keyframes animate-table {
  from {
    opacity: 0;
    -webkit-transform: translateY(-1rem);
            transform: translateY(-1rem);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes animate-table {
  from {
    opacity: 0;
    -webkit-transform: translateY(-1rem);
            transform: translateY(-1rem);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
/* end of table onload animation */

th {
  text-align: left;
  padding: 0.5rem 1rem;
  color: #444;
  text-transform: capitalize;
}

td {
  padding: 0.7rem 1rem;
  transition: all 0.5 linear;
}

table.light td,
table.light th {
  border-bottom: 0.001rem solid #e7e7e7;
}

table.checkered tbody tr:nth-child(even) {
  background-color: var(--app-table-checkered);
}

table tbody tr:hover {
  cursor: pointer;
  background-color: hsl(0deg 0% 96%) !important;
}

/* table row animation on hover */
table.hover-animation tbody tr:hover {
  /* background-color: hsl(0deg 0% 96%) !important; */
  /* cursor: pointer; */
  transition: -webkit-transform 0.1s;
  transition: transform 0.1s;
  transition: transform 0.1s, -webkit-transform 0.1s;
  -webkit-animation: animate-table 0.05s linear forwards;
          animation: animate-table 0.05s linear forwards;
  position: relative;
}

/* this fixes the flicking behavior on hover */
table.hover-animation tbody tr:hover:after {
  content: " ";
  position: absolute;
  left: 0;
  width: 100%;
  height: 150%;
}


#more-options-root {
 display: flex;
justify-content: flex-end;

}

#more-options-container {
  display: flex;
  margin-right: 20px;
}

#more-option-item {
  width: 40px;
  height: 40px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 100%;
  margin-right: 10px;
  -webkit-animation: slide_in 0.3s ease-in-out  forwards;
          animation: slide_in 0.3s ease-in-out  forwards;
}

#more-icon-container {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
}
#more-option-item:hover,
#more-icon-container:hover {
  color: white;
  background-color: var(--app-accent-pink);
}

.cancel:hover {
  background-color: tomato;
  color: white !important;
}


.view-more-icon-container {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items:center;
}



@-webkit-keyframes slide_in {
  0% {
    -webkit-transform: translateX(90%);
            transform: translateX(90%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}



@keyframes slide_in {
  0% {
    -webkit-transform: translateX(90%);
            transform: translateX(90%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
#com-image-container img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  object-fit: cover;
  border: 3px solid var(--app-accent-pink);
}

#image-and-verify-container {
  display: flex;
  padding: 20px;
}

#company-name-container{
    margin-top: 10px;
}
#company-name-container span{
    font-size: 1.1rem;
    font-weight: bold;
    color: var(--app-theme);
    margin-left: 10px;
}
#invest-card-wrapper {
  border: 1px solid whitesmoke;
}
#badge-root-wrapper{
  margin-top: 10px;
  margin-bottom: 5px;
}
#learn-more-txt-container {
  margin-left: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
#learn-more-txt-container span{
  color: #0645AD ;
  cursor: pointer;
  font-weight: bold;
}

#name-and-desc-container,
#inest-money-container {
  padding-left: 15px;
  padding-right: 15px;
}
#name-and-desc-container h3 {
  margin-bottom: 15px;
  font-size: 1.1rem;
}
#name-and-desc-container p {
  font-size: 0.9rem;
  text-align: justify;
}


#inest-money-container {
  margin-top: 1.5rem;
}

#subscriptions-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}
#subscriptions-container div span, #notable-investors-container span, #return-percentages-container div span{
  color: grey;
  font-size: 0.8rem;
}
#notable-investors-container p {
    font-size: 0.9rem;
    font-weight: bold;
    margin-top: 8px;
}

#notable-investors-container{
    margin-bottom: 1.5rem;
}

#return-percentages-container{
    display: flex;
    justify-content: space-between;
}
#return-percentages-container div p{
    margin-top: 8px;
}



.invest-card-wrapper:hover {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
  transition: 0.3s ease-out;
}

.invest-card-wrapper:active {
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
  transition: 0.2s ease-out;
}

#footer-btns-container{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
border-top: 1px solid whitesmoke;
}

#view-more-btn-container{
  display: flex;
  justify-content: center;
  align-items: center;
  padding:  20px;
  color: var(--app-theme);
  background-color: white;
  /* width: 30%; */
  cursor: pointer;
  /* border: 1px solid var(--app-theme); */
}
#view-more-btn-container svg{
  font-size: 1rem;
  margin-bottom: 5px;
  margin-left: 8px;
}
#view-more-btn-container:hover{
  /* background-color: var(--app-theme); */
  color:var(--app-accent-pink);

}

.inv-card-badge-container{
  padding: 10px 20px;
  background-color: rgb(235 255 235);
  color: #1d5213;
}
.inv-card-badge-container p{
  margin: 0;
  font-size: 0.8rem;
  text-align: center;
  font-weight: bold;
}

.pending{
  background-color: rgb(213 238 255);
  color: #0f4768 !important;
   
}
.pending p{
/* color: white !important; */
}

.user-investment-items-wrapper {
  margin-top: 1rem;
}
.user-investment-items-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 15px;
  -webkit-column-gap: 15px;
          column-gap: 15px;
  grid-row-gap: 30px;
  row-gap: 30px;
  margin-bottom: 2rem;
}
.investment-item-contain {
  margin-right: 20px;
  border-radius: 20px;
}
.investment-item-contain img {
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  width: 100%;
  height: 150px;
}
.investment-info-conatiner {
  padding: 2px 20px 20px 20px;
}
.investment-info-conatiner h3 {
  font-size: 1rem;
}

.opp-info-items-container {
  margin-top: 0.9rem;
  display: flex;
  justify-content: space-between;
}

.pps-container,
.shares-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pps-container span {
  color: var(--toast-success-color);
  font-weight: bold;
  font-size: 1.3rem;
  margin-left: 8px;
  margin-bottom: 8px;
}

.shares-container span {
  color: var(--app-theme);
  font-weight: bold;
  font-size: 1.3rem;
  margin-left: 8px;
  margin-bottom: 8px;
}


.investment-item-contain:hover {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
  transition: 0.3s ease-out;
}

.investment-item-contain:active {
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
  transition: 0.2s ease-out;
}

#no-investments-container{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80vw;
  height: 70vh;
}


.response-btns{
    padding: 15px 35px;
    border-radius: 8px;
    margin-top: 20px;
    cursor: pointer;
    width: 26%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.1rem;
   
}
.response-btns svg{
  margin-right: 8px;
  margin-bottom: 5px;
}

.accept-item{
    color: white;
    background-color: var(--toast-success-color) !important;
}
.accept-item:hover{
    color: white;
    background-color: green !important;
}

.reject{
  border: 1px solid tomato;
  color: tomato;
}
.flex-me{
    display:flex;
    justify-content: space-between;
  }
.stats-root{
  margin-bottom: 3rem;
}
.money-section {
 position: absolute;
 top: -20px;
 right: 10px;
 }



 /* stats */
 .partnership-opp-name{
       display: flex;
    justify-content: center;
    align-items: center;
 }

 .opp-valuation h1{
font-size: 3rem;
color: var(--app-theme);
 }
 .investments-wrapper-root{
   width: 90%;
    margin: auto;
    margin-top: 2rem ;
 }

 .space-me-right{
  margin-right: 2rem;
}



 @media (max-width: 480px) {
 .user-investment-items-wrapper {
  grid-template-columns: repeat(1, 1fr);
  grid-row-gap: 20px;
  row-gap: 20px;
  margin-bottom: 2rem;
}
 .investments-wrapper-root{
   width: 95%;
 }
 .stats-root{
   display: block;
 }

 .partnership-opp-name{
   margin-bottom: 1.6rem;
   align-items: flex-start;
 }

 .opp-valuation h1, .right-of-title-box h1 {
    font-size: 1.7rem;
    color: var(--app-theme);
}

.vanish{
  display: none;
}

.right-of-title-box{
  padding-right: 0px;
  display: block;
}
 }
.tab-headers-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
}

.tab-header {
  padding: 10px 20px;
  cursor: pointer;
  border: solid 1px antiquewhite;
  margin: 5px;
  border-radius: 5px;
}

.tab-header:hover {
  background: var(--app-theme-lighter);
  border-color: var(--app-theme-lighter);
  box-shadow: var(--elevate-1);
  transition: 0.3s ease-out;
}

.tab-header:hover p {
  color: white;
  transition: 0.3s ease-out;
}
.tab-header p {
  color: var(--app-theme-lighter);
  margin: 0px;
}

.tab-header:active {
  background: antiquewhite;
  border-color: antiquewhite;
  box-shadow: 0 0 0;
  transition: 0.1s ease-out;
}

.tab-header:active p {
  color: var(--app-theme-lighter);
  transition: 0.1s ease-out;
}

.tab-selected {
  background: var(--app-theme-lighter);
  border-color: var(--app-theme-lighter);
  box-shadow: var(--elevate-2);
  transition: 0.3s ease-out;
}

.tab-selected p {
  color: white;
}

@-webkit-keyframes move-in-from-left {
  from {
    opacity: 0;
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
  }

  to {
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes move-in-from-left {
  from {
    opacity: 0;
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
  }

  to {
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}
.tab-content-area {
  width: 100%;
  opacity: 0;
  -webkit-animation: move-in-from-left;
          animation: move-in-from-left;
  -webkit-animation-duration: 0.4s;
          animation-duration: 0.4s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

:root {
  --elevate-float: 0 1px 0px 0 rgba(0, 0, 0, -1.84),
    0 2px 10px 0 rgba(0, 0, 0, 0.1);
  --elevate-half: 0 1px 0px 0 rgb(0 0 0 / 0%), 0 2px 4px 0 rgb(0 0 0 / 3%);
  --elevate-1: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  --elevate-1-half: 0 5px 11px 0 rgba(0, 0, 0, 0.18),
    0 4px 15px 0 rgba(0, 0, 0, 0.15);
  --elevate-2: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  --elevate-3: 0 12px 15px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
  --elevate-4: 0 16px 28px 0 rgba(0, 0, 0, 0.22),
    0 25px 55px 0 rgba(0, 0, 0, 0.21);
  --eleveate-5: 0 27px 24px 0 rgba(0, 0, 0, 0.2),
    0 40px 77px 0 rgba(0, 0, 0, 0.22);
}
.elevate-float {
  box-shadow: 0 1px 0px 0 rgba(0, 0, 0, -1.84),
    0 2px 10px 0 rgba(0, 0, 0, 0.1);
  box-shadow: var(--elevate-float);
  -webkit-box-shadow: 0 1px 0px 0 rgba(0, 0, 0, -1.84),
    0 2px 10px 0 rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: var(--elevate-float);
}
.elevate-half {
  box-shadow: 0 1px 0px 0 rgb(0 0 0 / 0%), 0 2px 4px 0 rgb(0 0 0 / 3%);
  box-shadow: var(--elevate-half);
  -webkit-box-shadow: 0 1px 0px 0 rgb(0 0 0 / 0%), 0 2px 4px 0 rgb(0 0 0 / 3%);
  -webkit-box-shadow: var(--elevate-half);
}
.elevate-1 {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: var(--elevate-1);
}
.elevate-1-half {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18),
    0 4px 15px 0 rgba(0, 0, 0, 0.15);
  box-shadow: var(--elevate-1-half);
}
.elevate-2 {
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  box-shadow: var(--elevate-2);
}

.elevate-3 {
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
  box-shadow: var(--elevate-3);
}

.elevate-4 {
  box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22),
    0 25px 55px 0 rgba(0, 0, 0, 0.21);
  box-shadow: var(--elevate-4);
}

.elevate-5 {
  box-shadow: var(--elevate-5);
}

:root {
  --default-font-family: "Google Sans", sans-serif;
}

html {
  font-family: "Google Sans", sans-serif;
  font-family: var(--default-font-family);
  font-family: 400;
}

.put-me-inline {
  display: inline-block;
}

@-webkit-keyframes move-up-animation {
  from {
    -webkit-transform: translateY(80px);
            transform: translateY(80px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes move-up-animation {
  from {
    -webkit-transform: translateY(80px);
            transform: translateY(80px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    opacity: 1;
  }
}

@-webkit-keyframes move-down-animation {
  from {
    -webkit-transform: translateY(-80px);
            transform: translateY(-80px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes move-down-animation {
  from {
    -webkit-transform: translateY(-80px);
            transform: translateY(-80px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    opacity: 1;
  }
}

.form-title {
  color: var(--app-theme);
}
.main-container {
  width: 100%;
  padding: 10px 25px;
  text-align: left;
  border-radius: 6px;
  box-sizing: border-box;
}

.label-css {
  font-size: 15px;
  color: #bdbcbc;
  margin: 10px 0px;
  display: block;
}

.form-bottom-nav {
  display: flex;
  padding: 15px 0px;
  align-items: center;
  justify-content: flex-end;
}

.error-label-css {
  padding: 10px 20px;
  font-size: 15px;
  font-weight: bolder;
  font-family: inherit;
  background: #f9e9e9;
  color: maroon;
  border-radius: 3px;
  display: inline-block;
  margin-right: 4px;
}

.success-label-css {
  padding: 10px 20px;
  font-size: 15px;
  font-weight: bolder;
  font-family: inherit;
  background: #edfde3;
  color: #3a5236;
  border-radius: 3px;
}

.page-round-btn-css {
  display: inline-block;
  padding: 15px 17px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 100%;
  border: solid 2px var(--app-theme);
  cursor: pointer;
  margin: 4px;
  color: var(--app-theme);
}

.page-round-btn-css svg {
  font-size: 30px;
}

.page-round-btn-css:hover {
  background: var(--app-theme-hover);
  border-color: var(--app-theme-hover);
  color: white;
  transition: 0.2s ease-out;
}

.page-round-btn-css:hover {
  background: var(--app-theme-active);
  border-color: var(--app-theme-active);
  color: white;
  transition: 0.2s ease-out;
}

.page-active-css {
  background: var(--app-theme-active);
  color: white;
  border-color: var(--app-theme-active);
}

.page-active-css:hover {
  background: antiquewhite;
  color: var(--app-theme-hover);
  transition: 0.2s ease-out;
}

.multi-step-main-container-css {
  min-height: 60vh;
  width: 100%;
  padding: 0px 10%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  width: 80vw;
  position: relative;
}

.bottom-nav-btns-container {
  width: 100%;
  padding: 25px;
  position: absolute;
  bottom: -90px;
  left: 0;
  padding-left: 20%;
}

.disable-round-btns {
  border-color: Grey;
  opacity: 0.5;
}

.disable-round-btns:hover {
  color: grey;
  border-color: grey;
  background: white;
}

.disable-round-btns:active {
  background: white;
  color: grey;
}

.disable-round-btns svg {
  color: grey;
}

.move-up-animation-css {
  -webkit-animation: move-up-animation 0.4s ease-out;
          animation: move-up-animation 0.4s ease-out;
}

.move-down-animation-css {
  -webkit-animation-delay: 4s;
          animation-delay: 4s;
  -webkit-animation: move-down-animation 0.3s ease-out;
          animation: move-down-animation 0.3s ease-out;
}

.textbox {
  margin: 10px 0px;
  padding: 13px;
  font-size: 16px;
  border: solid 1px var(--app-theme-lighter);
  width: 100%;
  font-family: inherit;
  box-sizing: border-box;
}
.input {
  border-left-width: 5px;
}
.textarea {
  border-bottom-width: 5px;
  resize: none;
}
.textbox:focus {
  outline: 0;
}

.textbox::-webkit-input-placeholder {
  color: var(--app-placeholder-grey);
}

.textbox:-ms-input-placeholder {
  color: var(--app-placeholder-grey);
}

.textbox::placeholder {
  color: var(--app-placeholder-grey);
}

.increment-textbox {
  display: flex;
  justify-content: space-evenly;
  /* box-shadow: 0 0 1rem rgba(73, 72, 72, 0.2); */
  width: 250px !important;
  height: 45px;
}

.increment-textbox input {
  border: 0;
  outline: 0;
  text-align: center;
  font-family: sans-serif;
  font-size: 1rem;
  padding: 0.8rem 1rem;
  min-width: 0rem;
  min-height: 0 !important;
}

.increment-textbox .btn {
  color: #777;
  background: rgba(211, 211, 211, 0.2);
  padding: 0rem 1rem;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s linear;
  width: 1rem;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.increment-textbox .btn:first-child:hover {
  background-color: tomato;
  color: white;
}

.increment-textbox .btn:last-child:hover {
  background-color: rgb(14, 202, 86);
  color: white;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.radio-group-empty-circle {
  height: 25px;
  width: 25px;
  border: solid 4px var(--app-theme-lighter);
  margin: 4px;
  border-radius: 100%;
}

.radio-group-default-container {
  padding: 10px;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
}

.radio-group-default-container p {
  font-size: 17px;
  margin: 4px;
  text-transform: capitalize;
  color: var(--app-theme-lighter);
}

.radio-group-default-container:hover p {
  color: var(--app-theme-hover);
  transition: 0.2s ease-in;
}
.radio-group-default-container:active p {
  color: var(--app-theme-active);
  transition: 0.2s ease-in;
}

.radio-group-default-container:hover .radio-group-empty-circle {
  border-color: var(--app-theme-hover);
  transition: 0.2s ease-in;
}

.radio-group-default-container:active .radio-group-empty-circle {
  color: var(--app-theme-active);
  transition: 0.2s ease-in;
}

.radio-group-default-container:hover .hovering-radio-circle {
  color: var(--app-theme-hover);
  transition: 0.2s ease-in;
}

.radio-group-default-container:active .hovering-radio-circle {
  color: var(--app-theme-active);
  transition: 0.2s ease-in;
}

.hovering-radio-circle {
  height: 20px;
  width: 20px;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-52%, -52%);
          transform: translate(-52%, -52%);
  left: 50%;
  background: var(--app-theme-lighter);
}

.checkbox-empty-box {
  height: 25px;
  width: 25px;
  border: solid 4px var(--app-theme-lighter);
  margin: 4px;
  border-radius: 2px;
}

.checkbox-default-container {
  padding: 10px;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
}

.checkbox-default-container p {
  font-size: 17px;
  margin: 4px;
  text-transform: capitalize;
  color: var(--app-theme-lighter);
}

.checkbox-default-container:hover p {
  color: var(--app-theme-hover);
  transition: 0.2s ease-in;
}
.checkbox-default-container:active p {
  color: var(--app-theme-active);
  transition: 0.2s ease-in;
}

.checkbox-default-container:hover .checkbox-empty-box {
  border-color: var(--app-theme-hover);
  transition: 0.2s ease-in;
}

.checkbox-default-container:active .checkbox-empty-box {
  color: var(--app-theme-active);
  transition: 0.2s ease-in;
}

.checkbox-default-container:hover .hovering-checkmark {
  color: var(--app-theme-hover);
  transition: 0.2s ease-in;
}

.checkbox-default-container:active .hovering-checkmark {
  color: var(--app-theme-active);
  transition: 0.2s ease-in;
}

.hovering-checkmark {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  left: 50%;
  color: var(--app-theme-lighter);
}

.pdf-preview-box {
  cursor: pointer;
  border-radius: 10px;
  width: 300px;
  height: 300px;
  border-radius: 5px;
  background: white;
  margin: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: var(--elevate-float);
}

.pdf-preview-box svg {
  font-size: 9rem;
  color: maroon;
}

.pdf-preview-box:hover {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
  transition: 0.3s ease-out;
}

.pdf-preview-box:active {
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
  transition: 0.2s ease-out;
}
.link-btn {
  font-size: medium;
  text-decoration: none;
  margin: 10px;
}

.link-btn {
  text-decoration: underline;
}
.thumbnail-area {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.thumbnail-img-baby {
  position: absolute;
  bottom: 0px;
  left: -18px;
  -webkit-transform: rotate(20deg);
          transform: rotate(20deg);
  height: 50px;
  width: 50px;
  cursor: pointer;
  border: solid 2px transparent;
  object-position: center;
  margin: 6px;
  object-fit: cover;
  border-radius: 4px;
}

.thumbnail-img-baby:active {
  -webkit-transform: scale(3);
          transform: scale(3);
  border-color: inherit;
  transition: 0.2s ease-out;
}
.thumbnail-img-baby:hover {
  border-color: var(--app-theme-lighter);
  transition: 0.2s ease-in-out;
}

.thumbnail-img {
  height: 80px;
  width: 80px;
  object-fit: cover;
  border-radius: 6px;
  object-position: center;
  margin: 6px;
  cursor: pointer;
  border: solid 2px transparent;
}

.thumbnail-img:hover {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
  border-color: var(--app-theme-lighter);
  transition: 0.2s ease-in-out;
}

.thumbnail-img:active {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  transition: 0.1s ease-out;
}

.thumbnail-pdf-box {
  height: 80px;
  width: 80px;
  border-radius: 6px;
  margin: 6px;
  cursor: pointer;
  border: solid 2px transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: var(--elevate-float);
}

.thumbnail-pdf-box:hover {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
  border-color: var(--app-theme-lighter);
  transition: 0.2s ease-in-out;
}

.thumbnail-pdf-box:active {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  transition: 0.1s ease-out;
}

.thumbnail-pdf-box svg {
  font-size: 3rem;
  color: maroon;
}
.uploader-container {
  border: dashed 2px #efefef;
  border-radius: 5px;
  padding: 15px;
  padding-top: 5%;
  padding-bottom: 5%;
  min-height: 300px;
  margin-top: 10px;
}

.uploader-icon {
  color: var(--app-theme-lighter);
  cursor: pointer;
  font-size: 70px;
  border: solid 1px #f5f5f5;
  border-radius: 100%;
  padding: 50px;
}

.uploader-icon:hover {
  background: var(--app-theme-hover);
  color: white;
  transition: 0.2s ease-out;
}

.uploader-icon:active {
  background: var(--app-theme-active);
  transition: 0.2s ease-out;
}

.uploader-preview-img {
  cursor: pointer;
  border-radius: 10px;
  width: 300px;
  height: 300px;
  object-fit: contain;
  border-radius: 5px;
  background: #f9f9f9;
  margin: 7px;
}

.uploader-preview-img:hover {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
  transition: 0.3s ease-out;
}

.uploader-preview-img:active {
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
  transition: 0.2s ease-out;
}

.thumb-close {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 5px 10px;
  border-radius: 100px;
  background: white;
  box-shadow: var(--elevate-float);
  cursor: pointer;
  z-index: 20;
}

.thumb-close:hover {
  background: var(--app-theme-danger);
  transition: 0.1s ease-out;
}

.thumb-close:active {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
  transition: 0.1s ease-out;
}
.thumb-close:hover svg {
  color: white;
  transition: 0.1s ease-out;
}

.default-dropdown-selector {
  display: inline-flex;
  cursor: pointer;
  border: solid 0px transparent;
  border-bottom-width: 4px;
  padding: 5px;
  padding-bottom: 10px;
  text-transform: capitalize;
}
.default-dropdown-selector:hover {
  border-bottom-color: var(--app-theme-lighter);
}

.full-width-selector {
  width: 100%;
  padding: 15px 20px;
  border: solid 2px var(--app-theme-lighter);
  border-radius: 4px;
  display: flex;
  cursor: pointer;
  text-transform: capitalize;
  box-sizing: border-box;
}

.full-width-selector span {
  color: var(--app-theme-lighter);
  text-transform: capitalize;
}

.full-width-selector:hover {
  border-color: var(--app-theme-hover);
  transition: 0.2s ease-out;
  /* background: var(--app-theme-lighter); */
}
.full-width-selector-single:hover {
  border-color: var(--app-theme-hover);
  transition: 0.2s ease-out;
  background: var(--app-theme-lighter);
}

.full-width-selector-single:hover span {
  color: white !important;
  transition: 0.2s ease-out;
}
.full-width-selector-single:hover svg {
  color: white !important;
  transition: 0.2s ease-out;
}

.dropdown-children-container {
  position: absolute;
  top: 30px;
  left: 0px;
  z-index: 3;
  border-radius: 5px;
  background: white;
}

.dropdown-children-container-full {
  position: absolute;
  top: 57px;
  left: 0px;
  z-index: 3;
  border-radius: 5px;
  width: 100%;
  background: white;
  box-sizing: border-box;
    max-height: 28vh;
  overflow-y: auto;
}
.dropdown-item {
  cursor: pointer;
  padding: 15px 15px;
  color: black;
  text-align: left;
  text-transform: capitalize;
  overflow-wrap: break-word;

}
.dropdown-item:hover {
  background: var(--app-theme-lighter);
  color: white;
  transition: 0.2s ease-out;
}

.dropdown-item:active {
  background: var(--app-theme-hover);
}

.ghost-curtain {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: transparent;
  opacity: 0;
}

.selected-item {
  background: var(--app-theme-lighter);
  color: white !important;
}

.selected-item:hover {
  background: antiquewhite !important;
  color: black !important;
  transition: 0.2s ease-out;
}

.dropdown-caret {
  margin-left: auto;
  color: var(--app-theme-lighter);
}

.ribbon {
  padding: 6px 16px;
  background: #efefef;
  border-radius: 55px;
  font-size: 0.8rem;
  margin-bottom: 10px;
  margin: 0px 2px;
  cursor: pointer;
  color: black !important;
}
.ribbon:hover {
  background: #e8e8e8;
  box-shadow: var(--elevate-1);
  transition: 0.2s ease-out;
}

.ribbon svg {
  color: var(--app-theme-danger);
  margin-left: 4px;
}

@-webkit-keyframes drop-down {
  from {
    opacity: 0;
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@keyframes drop-down {
  from {
    opacity: 0;
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

.animate-drop-down {
  opacity: 0;
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
  -webkit-animation: drop-down 0.3s forwards;
          animation: drop-down 0.3s forwards;
}


.search-box-wrapper{
  width: 93%;
  position: relative;
  padding: 5px 10px;
}

.search-box-wrapper input{
  width: 100%;
  padding: 10px 15px;
  border: 1px solid var(--app-theme);
  margin-top: 10px;
  border-radius: 5px;
}
.search-box-wrapper input:focus{
outline-color: var(--app-theme);
}
.search-box-wrapper svg{
position: absolute;
right: -11px;
top: 23px;
color: var(--app-theme);
}


@media (max-width: 480px) {
.search-box-wrapper {
    width: 83%;
}
}
.textbox {
  margin: 10px 0px;
  padding: 13px;
  font-size: 16px;
  border: solid 1px var(--app-theme-lighter);
  width: 100%;
  font-family: inherit;
  box-sizing: border-box;
}
.input {
  border-left-width: 5px;
}
.textarea {
  border-bottom-width: 5px;
  resize: none;
}
.textbox:focus {
  outline: 0;
}

.textbox::-webkit-input-placeholder {
  color: var(--app-placeholder-grey);
}

.textbox:-ms-input-placeholder {
  color: var(--app-placeholder-grey);
}

.textbox::placeholder {
  color: var(--app-placeholder-grey);
}

.ghost-curtain {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: transparent;
  opacity: 0;
}

.auto-dropdown-children-container-full {
  position: absolute;
  top: 60px;
  left: 0px;
  z-index: 3;
  border-radius: 5px;
  width: 100%;
  background: white;
  box-sizing: border-box;
}

.auto-dropdown-item {
  cursor: pointer;
  padding: 15px 15px;
  color: black;
  text-align: left;
  text-transform: capitalize;
  overflow-wrap: break-word;
}
.auto-dropdown-item:hover {
  background: var(--app-theme-lighter);
  color: white;
  transition: 0.2s ease-out;
}

.auto-dropdown-item:active {
  background: var(--app-theme-hover);
}

.selected-item {
  background: var(--app-theme-lighter);
  color: white !important;
}

.selected-item:hover {
  background: antiquewhite !important;
  color: black !important;
  transition: 0.2s ease-out;
}

.tag-item{
    padding: 10px 15px;
    background-color: white;
    border-radius: 30px;
    cursor: pointer;
}
.tag-item svg{
    color: tomato;
    margin-right: 8px;
    margin-top: 3px;
}
.tag-item:hover{
    background-color:#F4D03F;
}
.tag-container{
  display: inline-block;
}
#bank-item-container {
  display: flex;
  padding: 20px;
  border-radius: 10px;
}
#mobile-wallet-wrapper,
#bank-wallet-wrapper {
  margin-bottom: 2rem;
}
.icon-container {
  height: 25px;
  width: 25px;
  border-radius: 100%;
  background-color: whitesmoke;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}
.bank-icon-container {
  height: 50px !important;
  width: 50px !important;
}
.mobile-icon-container svg {
  font-size: 1.5rem;
  color: white;
}
.bank-icon-container img {
  width: 50px;
  color: var(--app-theme);
}

#add-btn-container {
  margin-left: 1.5rem;
}
#bank-wallet-list-container,
#mobile-wallet-list-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 40px;
  gap: 40px;
  margin-top: 20px;
}
#bank-wallet-info-container {
  margin-top: 10px;
  width: 80%;
}

#bank-wallet-info-container div h3 {
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 10px;
}
#bank-wallet-info-container div span {
  margin-top: 6px;
}

#account-number {
  font-size: 1.1rem;
  font-weight: bold;
  color: var(--app-theme);
  margin-top: 5px;
}
#bank-name {
  margin-bottom: 10px;
}
.account-num {
  margin-top: 1rem !important;
  font-size: 1.4rem !important;
}
#network-name {
  padding: 7px 10px 0px 10px;
  font-size: 0.8rem;
  background-color: chocolate;
  color: white;
  border-radius: 5px;
}
.mtn {
  background-color: #fbca07 !important;
}
.airtel {
  background: linear-gradient(
    to right,
    #e72427 0%,
    #e72427 50%,
    #203c79 50%,
    #203c79 100%
  );
}
.vodafone {
  background-color: #e60000 !important;
}

#action-btn-container {
  display: flex;
  margin-top: 0.7rem;
}

.space-me-right {
  margin-right: 1rem;
}

.round-me {
  padding: 7px 15px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.delete {
  background-color: var(--app-theme-danger);
  color: white !important;
}
.edit {
  background-color: var(--app-clear-solid);
  color: white !important;
}

.bank-add-form {
  padding: 0 !important;
  min-height: 0px !important;
  margin-top: 1rem !important;
  width: 70vw !important;
}
.bottom-nav-styles {
  bottom: 5px !important;
  right: 50px !important;
  width: 30% !important;
  padding: 0;
  margin: auto;
}

#momo-wallet-info-container , #bank-wallet-info-container{
  position: relative;
  width: 100%;
}

.more-container {
  position: absolute;
  right: -4px;
  top: -6px;
  cursor: pointer;
}
.more-container svg {
  color: grey;
}

.delete-container{
  position: absolute;
  top: -14px;
  right: 9px;
  cursor: pointer;
  border-radius: 5px;
  width: 50%;
  background-color: white;
  border-radius: 5px;
padding-top: 10px;
padding-bottom: 6px;
}
.delete-container div{
/* margin-bottom: 15px; */
padding: 10px;

}
.delete-container div:hover {
background-color: var(--app-accent-pink);
color: white !important;

}
.delete-container div svg{
margin-right: 15px;

}




/* ==================== Payment Preference =========================== */

#payment-pref-root{
  margin-bottom:2rem;
}
.pref-table{
  border: 1px solid whitesmoke;
}
.table-action{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  border: 1px solid whitesmoke;
  border-radius: 30px;
}
.table-action:hover{
background-color: #fbca07;
}


/* elections css */

.election-form-item{
  margin-bottom: 25px;
}
.buttons-container{
  display: flex;
  justify-content: flex-end;
}

.election-form-container{
  width: 90%;
  margin-left: 2rem;
  margin-top: 1rem;
}


.error-msg-container{
  display: flex;
  background-color: rgb(238, 130, 111);
  padding: 8px 15px;
  border-radius: 10px;
  width: -webkit-max-content;
  width: max-content;
  margin-left: 2rem;

}
.error-msg-container-pref-page{
  display: flex;
  background-color: #85C1E9;
  padding: 8px 15px;
  border-radius: 8px;
  width: -webkit-max-content;
  width: max-content;
  margin-bottom: 1.5rem;
}

.msg-icon-container{
  background-color: tomato;
  color: white;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  display:flex;
  justify-content: center;
  align-items: center;

}
.msg-icon-container-pref{
  background-color: #3498DB;
  color: white;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  display:flex;
  justify-content: center;
  align-items: center;

}
.msg-icon-container svg{
font-size: 0.8rem;

}
.msg-text-container{
  color: white;
  margin-left: 10px;
  display:flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 480px) {
#bank-wallet-list-container,
#mobile-wallet-list-container {
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 16px;
  gap: 16px;
}

.icon-container {
  height: 3px;
  width: 4px;
}
.mobile-icon-container svg {
  font-size: 1rem;
}
.delete-container {
  width: 100%;
}
.bank-icon-container {
    height: 13px !important;
    width: 15px !important;
}
#bank-wallet-info-container div h3 {
    font-size: 1.1rem;
}
.account-num {
    margin-top: 1rem !important;
    font-size: 1rem !important;
}
}
.step-status{
  border-radius: 100%;
  border: 1px solid #d6dbdf;
  width: 15px;
  height: 15px;
  padding: 3px;
  text-align: center;
  margin-bottom: 1.2rem;
  cursor: pointer;
  color: #808b96;
  margin-right: 10px;
}

.stepper-header{
    display: flex;
    width: 90%;
    margin:auto;
}

.stepper-root{
    width: 100%;
    margin-top: 2rem;
}
.stepper-root-vertical{
    display:flex;
    width: 100%;
    margin-top: 2rem;
}

.components-container{
    width: 80%;
      margin: auto;
      margin-top: 3rem;
    position: relative;
    margin-bottom: 2rem;
}
.components-container-vertical{
    width: 70%;
    position: relative;
}
.stepper-headers-container{
    width:100%;
    display: flex !important;
}
.stepper-headers-container-vertical{
    width:20%;
}

.stepper-navigation-btns{
    position: fixed;
    bottom: 20px;
    display: flex;
    justify-content: space-between;
    width: 60%;
    margin: auto;
}

.left{
    position: absolute;
     left: 10px;
      bottom: 23px;
}
.right{
     position: absolute;
     right: 10px;
      bottom: 23px;
}
.navigation-btn{
    padding: 10px 25px !important;
    display: flex;
    background-color: white;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.navigation-btn:hover{
background-color: #F4D03F;
}
.navigation-btn svg{
margin-right: 10px;
margin-bottom: 5px;
}

.divider-line {
  height: 50px;
  background-color: grey;
  position: absolute;
  width: 1px;
  left:25px;
  top: -54px;
}

.active{
    background-color: #e51670;
}
.completed{
    background-color: #1d9d39;
}

.step-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 23%;
  position: relative;
}
.step-number {
  border-radius: 100%;
  border: 1px solid #d6dbdf;
  width: 50px;
  height: 50px;
  padding: 3px;
  text-align: center;
  margin-bottom: 1.2rem;
  cursor: pointer;
  color: #808b96;
}

.divider-line-horizontal {
  height: 1px;
  background-color: #d6dbdf;
  position: absolute;
  width: 55%;
  top: 26%;
  left: 70%;
  
}

.step-number-active {
  background: tomato !important;
  color: white;
  border: none;
}
.step-number-completed {
  background: seagreen;
  color: white;
  border: none;
}
.step-title-active {
  color: #85c1e9 !important;
  font-weight: bold;
}
.step-title {
  color: gray;
}

.center-me{
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 480px) {
.navigation-btn {
    padding: 10px 16px !important;
}
.stepper-navigation-btns{
    width: 80%;
}
.components-container{
    width: 100%;
}
.step-number {
  width: 30px;
  height: 30px;
}
.step-number svg{
  font-size: 1rem;
}
.stepper-headers-container{
    display: flex !important;
    justify-content: space-evenly;
}

.divider-line-horizontal {
    top: 20%;
    left: 100%;
}

}
.verify-item span{
font-size: 0.9rem;
color: grey;
}


.verify-item p{
font-size:1.2rem;
color: black;
font-weight: bold;
}

.verify-item{
    margin-bottom: 15px;
}

.bank-user-info{
    /* display: flex; */
    justify-content: space-evenly;
}

.verify-bank-wrapper{
display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 15px;
  -webkit-column-gap: 15px;
          column-gap: 15px;
  grid-row-gap:15px;
  row-gap:15px;
}
.sidebar-root-container {
  left: 0;
  top: 0;
  width: 280px;
  height: 100%;
  position: fixed;
  z-index: 3;
}
.text-for-dark {
  color: #ffe0fd;
}

.text-for-light {
  color: var(--app-theme);
}
.sb-theme-light {
  background: white;
}

.sb-theme-dark {
  background: var(--app-theme-lighter);
}

.sb-up {
  padding: 15px;
}
.sb-up-dark {
  background: var(--app-theme);
}

.sb-up-light {
  background: #fef8ff;
}

.sidebar-info-head {
  display: inline-flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
}

.sidebar-info-img {
  padding: 1px;
  height: 30px;
  width: 30px;
  object-fit: cover;
  margin: 3px;
  border-radius: 4px;
  border: solid 2px;
}

.dark-img-border {
  border-color: white;
}

.light-img-border {
  border-color: var(--app-theme-lighter);
}

.sb-info-dark {
  border: 2px solid var(--app-theme-lighter);
}

.sb-info-light {
  border: 2px solid white;
}
p {
  margin-bottom: 5px;
}

.sb-info-details {
  padding: 0px 10px;
}
.sb-info-p {
  font-size: 0.8rem;
  margin: 0px;
  font-weight: bold;
}

.sb-info-p-dark {
  color: white;
}
.sb-info-small-dark {
  /* color: #ffe0fd; */
  color: orange;
}

.sb-info-p-light {
  color: var(--app-theme-lighter);
}

.sb-info-small-light {
  color: var(--app-theme);
}

.sb-info-small {
  font-size: 0.8rem;
  font-weight: 500;
}

.sb-menu-item {
  display: flex;
  padding: 12px;
  cursor: pointer;
  align-items: center;
  justify-items: center;
  flex: 10 1;
  text-transform: capitalize;
  text-decoration: none;
}

.sb-menu-item-dark:hover svg,
.sb-menu-item-dark:hover p {
  color: var(--app-theme-lighter) !important;
  transition: 0.2s ease-out;
}
.sb-menu-item-dark:hover {
  background: white !important;
  transition: 0.2s ease-out;
}
.sb-menu-item:hover {
  background: var(--app-theme-lighter);
  color: white;
  transition: 0.2s ease-out;
}
.sb-menu-item:hover svg,
.sb-menu-item:hover p {
  color: white;
  transition: 0.2s ease-out;
}
.sb-menu-item svg {
  flex: 1 1;
}
.sb-menu-item p {
  margin: 0px;
  margin-left: 1rem;
  flex: 9 1;
}

.sb-menu-item p,
.sb-menu-item svg {
  color: var(--app-theme-lighter);
}
.sb-menu-item-dark p,
.sb-menu-item-dark svg {
  color: white;
}

.sb-bottom-menu-box {
  position: absolute;
  bottom: 0px;
  width: 100%;
}

.group-title {
  font-weight: bold;
  font-size: 0.8rem;
  /* color: var(--app-theme); */
  padding-left: 15px;
  opacity: 0.4;
  border: solid 0px white;
  text-transform: uppercase;
  margin: 8px 0px;
  /* padding: 10px; */
  /* border-top-width: 1px; */
  /* border-bottom-width: 1px; */
}

.company {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 14px;
  padding: 0px 5px;
  opacity: 0.7;
}

.company-dark p,
.company-dark svg {
  color: white;
}

.company-light p,
.company-light svg {
  color: var(--app-theme);
}

.sub-menu-box {
  margin-left: 15px;
}

.shrink-btn-container {
  position: absolute;
  right: -25px;
  height: 100%;
  background: transparent;
  display: flex;
  align-items: center;
}
.shrink-btn {
  border-radius: 55px;
  padding: 15px 17px;
  background: white;
  cursor: pointer;
  border: 0px;
}

.shrink-btn svg {
  color: var(--app-theme-lighter);
}

.shrink-btn:hover {
  background: var(--app-theme-lighter);
  transition: 0.2s ease-out;
}
.shrink-btn:hover svg {
  color: white;
  transition: 0.2s ease-out;
}

.shrink-width {
  width: 70px !important;
}

.shrink-icon-size {
  font-size: 1.3rem !important;
}

.shrink-mode-dropdown {
  background: white;
  position: absolute;
  right: -158px;
  margin-top: -45px;
  min-width: 155px;
  z-index: 20;
}

.ghost {
  position: fixed;
  height: 100%;
  width: 100%;
  background: white;
  z-index: 2;
  top: 0;
  left: 0;
  opacity: 0.1;
}

@-webkit-keyframes enlarge {
  from {
    width: 70px;
  }

  to {
    width: 270px;
  }
}

@keyframes enlarge {
  from {
    width: 70px;
  }

  to {
    width: 270px;
  }
}
@-webkit-keyframes shrink-animation {
  from {
    width: 270px;
  }

  to {
    width: 70px;
  }
}
@keyframes shrink-animation {
  from {
    width: 270px;
  }

  to {
    width: 70px;
  }
}
.enlarge {
  -webkit-animation-name: enlarge;
          animation-name: enlarge;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.shrink-animation {
  -webkit-animation-name: shrink-animation;
          animation-name: shrink-animation;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.menu-dark-selected {
  background: white;
  /* color: var(--app-theme); */
}

.menu-dark-selected svg,
.menu-dark-selected p {
  color: var(--app-theme);
}

.menu-dark-selected:hover {
  background: antiquewhite !important;
  transition: 0.2s ease-out;
}
.menu-dark-selected:hover svg,
.menu-dark-selected:hover p {
  color: var(--app-theme);
  transition: 0.2s ease-out;
}

.menu-light-selected {
  color: white;
  background: var(--app-theme);
}
.menu-light-selected svg,
.menu-light-selected p {
  color: white;
}

.menu-light-selected:hover {
  background: antiquewhite;
  transition: 0.2s ease-out;
}

.menu-light-selected:hover svg,
.menu-light-selected:hover p {
  color: var(--app-theme);
  transition: 0.2s ease-out;
}

@-webkit-keyframes show-from-left {
  from {
    -webkit-transform: translate(-20px, 20px);
            transform: translate(-20px, 20px);
  }

  to {
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px);
  }
}

@keyframes show-from-left {
  from {
    -webkit-transform: translate(-20px, 20px);
            transform: translate(-20px, 20px);
  }

  to {
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px);
  }
}

.show-from-left {
  -webkit-animation: show-from-left;
          animation: show-from-left;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}



@media (max-width: 480px) {
.shrink-btn-container {
  right: -15px;
}
.shrink-btn {
  padding: 9px 11px;
}

}
.enterprise-switch-wrapper {
  position: absolute;
  z-index: 1000000;
  width: 100vw;
  height: 100vh;
  overflow-y: hidden;
  -webkit-animation: slideIn 0.5s ease-in-out;
          animation: slideIn 0.5s ease-in-out;
}

.enterprise-switch-container {
  width: 25%;
  background-color: white;
  border-radius: 6px;
}
.on-shrink {
  position: absolute;
  left: 60px;
}

.item-divider {
  width: 85%;
  height: 1px;
  margin: auto;
  background-color: #d1cfcf;
  margin-top: 10px;
}

#current-enterprise-det-container {
  display: flex;
  width: 80%;
  margin: auto;
  /* justify-content: space-between; */
}

#curr-text-container {
  margin-top: 10px;
  margin-left: 10px;
}
#ent-img {
  border-radius: 100%;
}
#curr-text-container p {
  color: var(--app-theme);
  font-size: 1.4rem;
  font-weight: bold;
}
#curr-text-container span {
  color: grey;
  font-size: 0.9rem;
}

#other-ent-item-container {
  display: flex;
  padding-top: 10px;
  padding-left: 10px;
  padding-bottom: 10px;
}

#other-ent-item-container:hover {
  cursor: pointer;
  background-color: var(--app-accent-pink);
}
#other-ent-item-container:hover > div p {
  color: white !important;
}
.switch-btn {
  margin-top: 10px;
}
#other-ent-item-container p {
  color: var(--app-theme);
  font-size: 1.1rem;
  /* margin-top: 10px; */
}
#other-ent-item-wrapper {
  padding-left: 15px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  max-height: 20vh;
  overflow: auto;
}


@-webkit-keyframes slideIn {
  from {
    -webkit-transform: translateY(1vh);
            transform: translateY(1vh);
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}


@keyframes slideIn {
  from {
    -webkit-transform: translateY(1vh);
            transform: translateY(1vh);
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

.round-profile-icon {
  height: 42px;
  width: 42px;
  border-radius: 100%;
  padding: 1px;
  border: solid 2px var(--app-accent-pink);
  object-fit: cover;
  margin-left: 15px;
}
.root-wrapper {
  display: grid;
  grid-template-columns: var(--side-ratio) auto;
  width: 100%;
  height: 100vh;
}

.children-container {
  position: relative;
  display: grid;
  grid-template-rows: 0.3fr 7fr;
}

.side-pane {
  position: absolute;
  top: 61px;
  right: 0px;
  width: 300px;
  height: 100vh;
  background: white;
}

#back-root-container{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
#back-root-container div{
  padding: 10px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: tomato;
    background-color: white;
}
#back-root-container div p{
margin-top: 5px;
margin-left: 10px;
}

@-webkit-keyframes animate-side-pane {
  from {
    opacity: 0;
    -webkit-transform: translateX(300px);
            transform: translateX(300px);
  }

  to {
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes animate-side-pane {
  from {
    opacity: 0;
    -webkit-transform: translateX(300px);
            transform: translateX(300px);
  }

  to {
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

.animate-side-pane {
  opacity: 0;
  -webkit-animation: animate-side-pane;
          animation: animate-side-pane;
  -webkit-animation-duration: 0.4s;
          animation-duration: 0.4s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.settlements-table {
border: 1px solid whitesmoke;
}
#receipt-file svg{
    font-size: 1.5rem;
    color: #C0392B ;
    text-align: center;
}
.report-card-root{
    padding: 15px 20px;
    border-radius: 10px;
    margin-bottom: 10px;
}
#report-card-logo-and-name-container{
    display:flex;
}

#report-card-logo-container img{
width: 60px;
height: 60px;
border-radius: 100%;
object-position: center;
object-fit: cover;
border: 3px solid var(--app-accent-pink);

}
#report-card-logo-container {
    margin-right: 10px;
}

#report-card-opp-det p{
    margin-top: 5px;
}

#report-card-title-container{
    /* padding: 0px 20px; */
    margin-top: 20px;
}

.report-card-body-container{
    /* padding: 5px 20px; */
    margin-bottom: 1rem;
}
.report-card-body-container p{
    text-align: justify;
}

#report-card-footer-container{
    display: flex;
justify-content: flex-end;
margin-top: 10px;
}
.comment-btn-container{
    float: right;
    padding: 15px 20px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 50px;
    margin-right: 20px;
    cursor: pointer;
    border: 2px solid whitesmoke;
}
.comment-btn-container:hover{
background-color: #F4D03F;
/* color: white; */
}
.comment-btn-container span{
margin-left: 10px;
}
.comment-btn-container svg{
/* color: whitesmoke; */
font-size: 1rem;
color: grey;
}
#report-card-body-images img{
    width:100%;
    object-fit: cover;
    height:45vh;
    border-radius:10px;
}
#report-card-body-images video{
    width:100%;
    object-position: center;
    object-fit: fill;
    height:55vh;
    border-radius:10px;
    border:  1px solid #ccc;
}
#report-card-body-images{
 margin-top: 20px;
}

.divider{
    width: 100%;
    height: 1px;
    background-color: rgb(214, 213, 213);
}
.report-card-body-container{
     width: 90%;
  
     cursor: pointer
}
.report-card-body{
   margin-left:4.6rem;
}

@media (max-width: 480px) {
#report-card-logo-container img{
width: 39px;
height: 39px;

}
#report-card-body-images img{
    height: 100%;
}
.report-card-body-container{
     margin-left:0rem;
     width: 100%;
}
}
#image-preview-overlay-root{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}
#image-preview-overlay-root img{
    width: 95%;
    height: 95%;
    object-fit: contain;

}

.reports-list-container{
     width: 75%;
    margin-top: 2rem
}

@media (max-width: 480px) {
.reports-list-container{
     width: 100%;
   
}
}
#report-detail-logo-container img{
border-radius: 10px;
height: 110px;
width: 110px;
margin-right: 15px;
object-position: center;
object-fit: cover;
border: 4px solid var(--app-accent-pink);
}
.report-detail-root{
    width: 90%;
    margin: auto;
    margin-top: 1rem;
}
#report-title-and-body-container{
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: 95%;
}

.report-detail-body-container p{
line-height: 1.7;
text-align: justify;
font-size: 1.1rem;
margin-bottom: 20px;
}

.comments-list-container{
    margin-top: 2rem;
    margin-bottom: 2rem;
    /* padding: 15px 20px 0px 20px; */
    border-radius: 4px;
    border: 1px solid #ccc;
    /* max-height: 40vh;
    overflow-y: auto; */
    
}
#commentor-img-container img{
    width: 50px;
    height: 50px;
    border-radius: 100%;
    border:  2px solid rgb(138, 44, 44);
}

#commentor-info{
    margin-left:10px;
    margin-top: 5px;
}

#commentor-info small{
margin-left: 5px;
}

#comment-body{
margin-top: 10px;
}
#report-detail-body-images img{
    width:100%;
    /* object-fit: cover; */
    height:65vh;
    /* height: 100%; */
    border-radius:10px;
}
#report-detail-body-images video{
    width:100%;
    object-position: center;
    object-fit: fill;
    height:65vh;
    border-radius:10px;
    border:  1px solid #ccc;
}
#report-detail-body-images{
 margin-top: 20px;
}

.comment-item-container{
padding-bottom: 10px;
border-bottom: 1px solid #ccc;
padding:10px;
/* margin-top: 10px; */
cursor: pointer;

}
.comment-item-container:hover{
background-color:whitesmoke;

}

.comments-header{
    margin-top: 10px;
    margin-left: 20px;
}

.report-detail-body p{
    text-align: justify !important;
    font-size: 1.4rem !important;
}

@media (max-width: 480px) {
#report-detail-logo-container img{
height: 63px;
width: 68px;
}
.report-detail-root{
    margin-top: 0rem;
}
.report-detail-body p{
    font-size: 1rem !important;
}
}
.settlement-detail-root{
        width: 90%;
    margin: auto;
    margin-top: 1rem;
    /* background-color: aquamarine; */

}
.top-card{
    display: flex;
    justify-content: space-between;
    padding: 20px 20px;
    border-radius: 10px;
    width: 90%;
}
.settlent-info-container{
    display: flex;
    justify-content: space-between;
        width:50%;
        /* padding: 15px 20px; */
        border-radius: 10px;
}
.received-money-container{
    display:flex;
    color: grey;
    margin-bottom: 10px;
    margin-top: 15px;
}
.received-money-container h2{
    font-size: 3rem;
    color: var(--app-money-green);
}
.received-money-container p {
    font-size: 1.5rem;
    margin-top: 10px;

}

.total-received-title{
    font-size: 1.2rem;
    color: grey;
    font-weight: bold;
}

.pending{
    color: #5DADE2 !important;
}

.settlent-note-container{
    margin-top: 2rem;
    width: 95%;
}
.settlent-note-container p{
    text-align: justify;
}

#settlement-company-det h2{
font-size: 1.8rem;
color: var(--app-Theme);
}

.payment-distribution-container{
    margin-top: 2rem;
}

#comment-file-container{
    margin-top: 10px;
}
#comment-file-container img{
    width:100%;
    object-fit: cover;
    height:45vh;
    border-radius:10px;
      border:  1px solid whitesmoke;
}
#comment-file-container video{
    width:100%;
    object-position: center;
    object-fit: fill;
    height:45vh;
    border-radius:10px;
    border:  1px solid whitesmoke;
}
#comment-body{
    margin-left:60px;
    width: 90%;
}

.comment-file-container{
    display: flex;
    justify-content: center;
align-items: center;
flex-direction: column;
}
.comment-file-container p{
color: grey;
margin-top: 10px;
}

.file-wrapper{
    padding: 30px 40px;
}
.file-wrapper svg{
   font-size: 10rem;
}

.settlement-receipt-container{
    margin-top: 2rem;
    margin-bottom: 4rem;
}
.user-investment-details-container {
  display: flex;
  margin-top: 1rem;
}
/* yoo where did u go */
.comp-and-inv-info-container {
  width: 60%;
  margin-right: 3rem;
  padding: 2px 0px 20px 10px;
}
.inv-and-docs-container{
    padding: 15px;
    width: 30%;
}
.inv-detail-container {
  border-top: 0.7rem solid var(--app-theme);
  padding: 20px;
}

.comp-logo-namecontainer {
  display: flex;
}

.comp-logo-namecontainer img {
  height: 100px;
  width: 100px;
  object-fit: cover;
  border-radius: 5px;
  border: 2px solid var(--app-accent-pink);
}
.comp-logo-namecontainer div {
  margin-left: 20px;
  margin-top: 5px;
}
.comp-logo-namecontainer div span {
  color: #c0c0c0;
}
.comp-logo-namecontainer div h3 {
  color: var(--app-theme);
  font-size: 2.3rem;
  margin-top: 15px;
}

#comp-info-container p,
#inv-info-container p {
  text-align: justify;
}

#comp-info-container {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
#inv-detail-header{
  color: #696969;
  margin-bottom: 1.5rem;
}

.invest-btn-container{
  margin-top: 2rem;
}


#info-stats-container span{
  color: grey;
  font-size: 0.8rem;
}

#info-stats-container p{
    margin-top: 8px;
    font-size: 0.9rem;
    font-weight: bold;
}

#info-stats-container{
  margin-bottom: 1rem;
}

#doc-container{
  display: flex;
  margin-top: 2rem;
}
#doc-container:hover{
cursor: pointer;
}
#doc-container svg{
  margin-right: 10px;
  font-size: 1.3rem;
}
#doc-container p{
color: #696969;
}

.doc-list-container{
  max-height: 15vh;
  overflow-y: auto;
  margin-top: 1rem;
}

.doc-list-item{
  display:flex;
  padding: 10px 15px;
}
.doc-list-item:hover{
background-color:var(--app-theme);
cursor: pointer;
color: white;
}
.doc-list-item:hover > p{
color: white !important;
}
.doc-list-item:hover > svg{
color: white !important;
}

.doc-list-item svg{
margin-right: 10px;
color: tomato;
font-size: 1.5rem;
}
.doc-list-item p{
color: grey;
}



/* docs */
#investment-docs-root{
  width: 90%;
}


#doc-svg svg{
  font-size: 2rem;
}
#doc-name-txt{
  color:blue;
}

/* report */

#opp-detail-report-root{
  position: relative;
  width: 90%;
}
.list-root{
  margin-top: 1.5rem;
}


/* assets */

.opp-assets-table{
  border: 1px solid whitesmoke;
  width: 95%;
  margin-top: 20px;
}



/* status codes */


.aquisition-status-root{
  position: relative;
  margin-top: 2rem;
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 1fr;
}
.aquisition-info-container{
max-width: 86%;
}
.aquisition-desc-container p{
text-align: justify;
}
.reciept-upload-form-container{
  margin-top: 20px;
}

.status-root{
  padding: 15px 20px 5px 20px;
  display: flex;
  border-radius: 10px;
  margin-bottom: 30px;
  position: relative;
}
.status-item::after {
  content: " ";
  position: absolute;
  top: 50%;
  right: 100%; /* To the left of the tooltip */
  margin-top: -5px;
  border-width: 6px;
  border-style: solid;
  border-color: transparent white transparent transparent;
}
.completed-status-item::after {
  border-color: transparent #1d9d39 transparent transparent;
}
.status-root div p{
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 10px;
}
.status-root div span{
  font-size: 0.9rem;
  color: grey;
}

.completed{
  background-color: #1d9d39;
  border: #1d9d39;
  color: white ;
}
.completed div span{
  color: white !important;
}

.status-root::before{
    content: " ";
    position: absolute;
    height: 160%;
    width: 3px;
    background: #e7ebf1;
    left: -24px;
    top: -9px;
    z-index: 1;
}

.status-root::after {
    content: " ";
    position: absolute;
    z-index: 2;
    width: 15px;
    height: 15px;
    border: 2px solid #FFF;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    background-clip: padding-box;
    border-radius: 50%;
    background: #e7ebf1;
    left: -33px;
    top: 50%;
    margin-top: -7.5px;
}

.completed::after{
  background: #1d9d39;
}

.momo-option-container ol li{
  margin-bottom: 10px;

}
.bank-option-container ol li, .bank-option-container ul li{
  margin-bottom: 10px;

}
.flex-item{
  display: flex;
}


@media (max-width: 480px) {
.user-investment-details-container {
  display: block;
}

.flex-item{
  display: block;
}
.comp-logo-namecontainer img {
    height: 69px;
    width: 71px;
}
.comp-logo-namecontainer div {
    margin-left: 9px;
}
.comp-logo-namecontainer div h3 {
    margin-top: -1px;
    font-size: 1.4rem;
}


.comp-and-inv-info-container {
    width: 100%;
    margin-right: 0;
}
.inv-and-docs-container {
    width: 90%;
}
.response-btns {
    padding: 11px 32px;
    font-size: 1rem;
}

.aquisition-status-root{
  display: block;
}
.aquisition-info-container{
max-width: 95%;
margin-bottom: 3rem;
}
.status-root
{
  margin-left: 30px;
}

.status-root::before {
    left: -17px;
    top: -13px;
}
.completed::after {
    background: #1d9d39;
}
.status-root::after {
    left: -27px;
    top: 50%;
    margin-top: -9.5px;
}
#opp-detail-report-root{
  width: 100%;
}
}
.accordion-toggle {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.8em;
  margin-bottom: 10px;
}
.active-item{
    background-color: #ffe5ff ;
    color: var(--app-theme);  
    margin-bottom: 0px;
}

.accordion-toggle h3 {
  margin: 0;
}
.accordion-content {
   background-color: #fff3ff;
  padding: 1em;
}

.accordion-icon{
    font-size: 1.2rem !important;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
}
.tc-root{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
}

.header-container{
    margin-bottom: 1.2rem;
    margin-top: 1.2rem;
}

.header-container span{
  color: grey;
}
.header-container p{
 font-weight: bold;
 font-size: 2.4rem;
 margin-top: 10px;
}

.tc-body p{
text-align: justify;
margin-bottom: 1rem;
line-height: 1.8rem;
}

.tc-btn-container{
    display: flex;

}

.btn-item{
    padding: 15px 35px;
    border-radius: 8px;
    margin-right: 20px;
    margin-top: 20px;
    cursor: pointer;
}

.accept-item{
    color: white;
    background-color: #3f0f3f;
}
.accept-item:hover{
    color: white;
    background-color: var(--toast-success-color);
}
#user-setting-root {
  margin-top: 2rem;
  margin-left: 20px;
}

#profile-img {
  height: 130px;
  width: 150px;
  border-radius: 11px !important;
  padding: 1px;
  border: solid 2px var(--app-accent-pink);
  object-fit: cover;
}
#profile-img-container {
  position: relative;
}

#choose-icon {
  position: absolute;
  color: white;
  background-color: var(--app-accent-pink);
  font-weight: bold;
  cursor: pointer;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 10px;
  right: 11px;
  bottom: 34px;
}
#img-info-container {
  display: flex;
}
#info-container {
  margin-left: 20px;
  position: relative;
  padding-right: 20px;
}
#info-container h3 {
  font-size: 2rem;
  color: var(--app-theme);
  cursor: pointer;
}
.edit-details-svg {
  font-size: 1rem !important;
  color: var(--app-theme);
  margin-left: 6px;
  margin-bottom: 3px;
  display: none;
}
#info-container span {
  color: grey;
  font-size: 0.9rem;
}
#info-container p {
  margin-top: 3px !important;
}
#info-container h3:hover > .edit-details-svg {
  display: inline-block !important;
}
#biography-conatiner {
  margin-top: 2rem;
}
#biography-conatiner p {
  text-align: justify;
  color: dimgray;
  font-size: 1.2rem;
  line-height: 1.5;
}
#biography-conatiner h3 {
  font-size: 1.6rem;
  color: var(--app-theme);
  margin-bottom: 10px;
}
#biography-conatiner h3:hover > .edit-details-svg {
  display: inline-block !important;
}

.loading-img-container {
  position: absolute;
  background-color: rgba(16, 19, 21, 0.3);
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  border-radius: 20px;
}

#edit-user-info-text {
  font-size: 1.6rem;
  color: var(--app-theme);
  margin-bottom: 10px;
}

.edit-user-profile-btn {
  cursor: pointer;
  color: #5dade2;
  font-weight: bold;
  font-size: 1.1rem;
}

.verified-svg {
  font-size: 1.3rem;
}
#social-media-container {
  display: flex;
  margin-top: 6px !important;
}
#social-media-container svg {
  font-size: 1.2rem;
  color: var(--app-theme);
  cursor: pointer;
  margin-right: 17px;
}


.interests-container{
  margin-top: 2rem;
}
.interests-container h3{
   font-size: 1.6rem;
  color: var(--app-theme);
}
.interests-wrapper{
  display: flex;
  margin-top: 15px;
}

.interest-item{
      padding: 10px 15px;
    background-color: white;
    border-radius: 30px;
    margin-right: 10px;
    cursor: pointer;
}

.interest-item:hover{
    background-color:#F4D03F;
}

@media (max-width: 480px) {
#profile-img {
  height: 87px !important;
  width: 89px !important;

}
#choose-icon {

    height: 29px;
    width: 31px;
    right: 7px;
    bottom: 55px;
}
#info-container {
    margin-left: 5px !important;
}
#info-container h3 {
    font-size: 1rem !important;
}
}

#profile-img {
  height: 130px;
  width: 150px;
  border-radius: 20px;
  padding: 1px;
  border: solid 2px var(--app-accent-pink);
  object-fit: cover;
}

#img-info-container {
  display: flex;
}
#info-container {
  margin-left: 20px;
  position: relative;
}
#info-container P {

  margin-top: 8px;
}
#info-container h3 {
  font-size: 2rem;
  color: var(--app-theme);
  cursor: pointer;
}
.edit-details-svg {
  font-size: 1rem !important;
  color: var(--app-theme);
  margin-left: 6px;
  margin-bottom: 3px;
  display: none;
}
#info-container span {
  color: grey;
  font-size: 0.9rem;
}

#biography-conatiner {
 width: 90%;

}
#biography-conatiner p {
  text-align: justify;
  color: dimgray;
  font-size: 1.1rem;
  line-height: 1.7;
}
#biography-conatiner h3 {
  font-size: 1.6rem;
  color: var(--app-theme);
  margin-bottom: 10px;
}


.verified-svg{
  font-size: 1.3rem;
}

.partnerships-list{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 15px;
  -webkit-column-gap: 15px;
          column-gap: 15px;
  grid-row-gap: 30px;
  row-gap: 30px;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

#social-media-container{
  display: flex;
  margin-top: 15px;
}
#social-media-container svg{
  font-size: 1.2rem;
  color: var(--app-theme);
    cursor: pointer;
  margin-right: 17px;
}


.overlay-root-container{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}
#asset-detail-wrapper {
  width: 90%;
  margin: auto;
  margin-top: 2rem;
}

#asset-img {
  height: 120px;
  width: 140px;
  border-radius: 10px;
  padding: 1px;
  object-fit: cover;
  border: 2px solid var(--app-accent-pink);;
}
#asset-info-txts-container {
  margin-left: 25px;
}

#asset-detail-container,
#asset-doc-container {
  margin-top: 2.4rem;
}
#asset-doc-container {
  margin-bottom: 2rem;
}

#asset-detail-container p {
  text-align: justify;
  margin-top: 10px;
  line-height: 1.5;
}

#asset-info-txts-container h2 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: var(--app-theme);
}
#asset-info-txts-container h2 span {
  font-size: 1.3rem;
  color: var(--app-theme);
  font-weight: normal;
}

.asset-id-number {
  font-size: 1.4rem;
  font-weight: bold;
  color: var(--app-theme);
}

.view-file-btn {
  padding: 10px 20px;
  color: black;
  background-color: white;
  border-radius: 30px;
  width: -webkit-max-content;
  width: max-content;
  cursor: pointer;
}
.view-file-btn:hover {
  color: white;
  background-color: var(--app-accent-pink);
}


.img-and-title-container{
    display: flex;
}
.country-and-currency-root{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
}

.country-form-container{
    width: 60%;
}

.country-currency-notice{
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 25px;
}
.country-currency-notice h3{
    color: maroon;
    font-size: 1.4em;
    font-weight: bold;
}
.country-currency-notice p{
    color: #666;
    font-size: 1.1em;
    margin-top: 5px;
}

@media (max-width: 480px) {
    .country-form-container{
        width: 90%;
    }
}
