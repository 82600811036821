@import url("./../../shared/css/index.css");

.sidebar-root-container {
  left: 0;
  top: 0;
  width: 280px;
  height: 100%;
  position: fixed;
  z-index: 3;
}
.text-for-dark {
  color: #ffe0fd;
}

.text-for-light {
  color: var(--app-theme);
}
.sb-theme-light {
  background: white;
}

.sb-theme-dark {
  background: var(--app-theme-lighter);
}

.sb-up {
  padding: 15px;
}
.sb-up-dark {
  background: var(--app-theme);
}

.sb-up-light {
  background: #fef8ff;
}

.sidebar-info-head {
  display: inline-flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
}

.sidebar-info-img {
  padding: 1px;
  height: 30px;
  width: 30px;
  object-fit: cover;
  margin: 3px;
  border-radius: 4px;
  border: solid 2px;
}

.dark-img-border {
  border-color: white;
}

.light-img-border {
  border-color: var(--app-theme-lighter);
}

.sb-info-dark {
  border: 2px solid var(--app-theme-lighter);
}

.sb-info-light {
  border: 2px solid white;
}
p {
  margin-bottom: 5px;
}

.sb-info-details {
  padding: 0px 10px;
}
.sb-info-p {
  font-size: 0.8rem;
  margin: 0px;
  font-weight: bold;
}

.sb-info-p-dark {
  color: white;
}
.sb-info-small-dark {
  /* color: #ffe0fd; */
  color: orange;
}

.sb-info-p-light {
  color: var(--app-theme-lighter);
}

.sb-info-small-light {
  color: var(--app-theme);
}

.sb-info-small {
  font-size: 0.8rem;
  font-weight: 500;
}

.sb-menu-item {
  display: flex;
  padding: 12px;
  cursor: pointer;
  align-items: center;
  justify-items: center;
  flex: 10;
  text-transform: capitalize;
  text-decoration: none;
}

.sb-menu-item-dark:hover svg,
.sb-menu-item-dark:hover p {
  color: var(--app-theme-lighter) !important;
  transition: 0.2s ease-out;
}
.sb-menu-item-dark:hover {
  background: white !important;
  transition: 0.2s ease-out;
}
.sb-menu-item:hover {
  background: var(--app-theme-lighter);
  color: white;
  transition: 0.2s ease-out;
}
.sb-menu-item:hover svg,
.sb-menu-item:hover p {
  color: white;
  transition: 0.2s ease-out;
}
.sb-menu-item svg {
  flex: 1;
}
.sb-menu-item p {
  margin: 0px;
  margin-left: 1rem;
  flex: 9;
}

.sb-menu-item p,
.sb-menu-item svg {
  color: var(--app-theme-lighter);
}
.sb-menu-item-dark p,
.sb-menu-item-dark svg {
  color: white;
}

.sb-bottom-menu-box {
  position: absolute;
  bottom: 0px;
  width: 100%;
}

.group-title {
  font-weight: bold;
  font-size: 0.8rem;
  /* color: var(--app-theme); */
  padding-left: 15px;
  opacity: 0.4;
  border: solid 0px white;
  text-transform: uppercase;
  margin: 8px 0px;
  /* padding: 10px; */
  /* border-top-width: 1px; */
  /* border-bottom-width: 1px; */
}

.company {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 14px;
  padding: 0px 5px;
  opacity: 0.7;
}

.company-dark p,
.company-dark svg {
  color: white;
}

.company-light p,
.company-light svg {
  color: var(--app-theme);
}

.sub-menu-box {
  margin-left: 15px;
}

.shrink-btn-container {
  position: absolute;
  right: -25px;
  height: 100%;
  background: transparent;
  display: flex;
  align-items: center;
}
.shrink-btn {
  border-radius: 55px;
  padding: 15px 17px;
  background: white;
  cursor: pointer;
  border: 0px;
}

.shrink-btn svg {
  color: var(--app-theme-lighter);
}

.shrink-btn:hover {
  background: var(--app-theme-lighter);
  transition: 0.2s ease-out;
}
.shrink-btn:hover svg {
  color: white;
  transition: 0.2s ease-out;
}

.shrink-width {
  width: 70px !important;
}

.shrink-icon-size {
  font-size: 1.3rem !important;
}

.shrink-mode-dropdown {
  background: white;
  position: absolute;
  right: -158px;
  margin-top: -45px;
  min-width: 155px;
  z-index: 20;
}

.ghost {
  position: fixed;
  height: 100%;
  width: 100%;
  background: white;
  z-index: 2;
  top: 0;
  left: 0;
  opacity: 0.1;
}

@keyframes enlarge {
  from {
    width: 70px;
  }

  to {
    width: 270px;
  }
}
@keyframes shrink-animation {
  from {
    width: 270px;
  }

  to {
    width: 70px;
  }
}
.enlarge {
  animation-name: enlarge;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}

.shrink-animation {
  animation-name: shrink-animation;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}

.menu-dark-selected {
  background: white;
  /* color: var(--app-theme); */
}

.menu-dark-selected svg,
.menu-dark-selected p {
  color: var(--app-theme);
}

.menu-dark-selected:hover {
  background: antiquewhite !important;
  transition: 0.2s ease-out;
}
.menu-dark-selected:hover svg,
.menu-dark-selected:hover p {
  color: var(--app-theme);
  transition: 0.2s ease-out;
}

.menu-light-selected {
  color: white;
  background: var(--app-theme);
}
.menu-light-selected svg,
.menu-light-selected p {
  color: white;
}

.menu-light-selected:hover {
  background: antiquewhite;
  transition: 0.2s ease-out;
}

.menu-light-selected:hover svg,
.menu-light-selected:hover p {
  color: var(--app-theme);
  transition: 0.2s ease-out;
}

@keyframes show-from-left {
  from {
    transform: translate(-20px, 20px);
  }

  to {
    transform: translate(0px, 0px);
  }
}

.show-from-left {
  animation: show-from-left;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}



@media (max-width: 480px) {
.shrink-btn-container {
  right: -15px;
}
.shrink-btn {
  padding: 9px 11px;
}

}