.tag-item{
    padding: 10px 15px;
    background-color: white;
    border-radius: 30px;
    cursor: pointer;
}
.tag-item svg{
    color: tomato;
    margin-right: 8px;
    margin-top: 3px;
}
.tag-item:hover{
    background-color:#F4D03F;
}
.tag-container{
  display: inline-block;
}