.verify-item span{
font-size: 0.9rem;
color: grey;
}


.verify-item p{
font-size:1.2rem;
color: black;
font-weight: bold;
}

.verify-item{
    margin-bottom: 15px;
}

.bank-user-info{
    /* display: flex; */
    justify-content: space-evenly;
}

.verify-bank-wrapper{
display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 15px;
  row-gap:15px;
}