.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: fixed;
  width: 100vw;
  z-index: 1000;
  top: 0;
  left: 0;
  background: #f5eaff;
  opacity: 0;
  animation: 0.4s load-in forwards;
}

@keyframes load-in {
  from {
    opacity: 0;
    transform: scale(1.3);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}
