#more-options-root {
 display: flex;
justify-content: flex-end;

}

#more-options-container {
  display: flex;
  margin-right: 20px;
}

#more-option-item {
  width: 40px;
  height: 40px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 100%;
  margin-right: 10px;
  animation: slide_in 0.3s ease-in-out  forwards;
}

#more-icon-container {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
}
#more-option-item:hover,
#more-icon-container:hover {
  color: white;
  background-color: var(--app-accent-pink);
}

.cancel:hover {
  background-color: tomato;
  color: white !important;
}


.view-more-icon-container {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items:center;
}



@keyframes slide_in {
  0% {
    transform: translateX(90%);
  }
  100% {
    transform: translateX(0);
  }
}