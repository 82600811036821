:root {
  --app-theme: #3f0f3f;
  /* --app-theme-lighter: #531a6b; */
  --app-table-checkered: hsl(351deg 100% 98%);
  --app-theme-lighter: #4c164c;
  --app-theme-danger: rgb(207, 85, 85);
  --app-theme-hover: #9d41c3;
  --app-theme-active: #531a6b;
  --app-theme-success: #1d9d39;
  --app-theme-grey: #cdcdcd;
  --app-accent-pink: #e51670;
  --app-accent-orange: #fc8661;
  --app-money-green: #8bac2d;
  --app-money-green-lighter: #b8cf75;
  --app-trans-blue: #f1fbff;
  --app-trans-yellow: #fefdf2;
  --app-trans-pink: #fef4ff;
  --app-trans-purple: #fdf0ff;
  --app-settled-background: #d7e5d6;
  --app-deny-background: #e5d6d6;
  --app-clear-background: #d6e0e5;
  --app-clear-solid: #1d8e9d;
  --app-selling-background: #e5e2d6;
  --app-selling-solid: #cd792c;
  --app-negotiation-background: #dcd6e5;
  --app-negotiation-solid: #6d23b7;
  --app-placeholder-grey: #a2a2a2;
}
