.increment-textbox {
  display: flex;
  justify-content: space-evenly;
  /* box-shadow: 0 0 1rem rgba(73, 72, 72, 0.2); */
  width: 250px !important;
  height: 45px;
}

.increment-textbox input {
  border: 0;
  outline: 0;
  text-align: center;
  font-family: sans-serif;
  font-size: 1rem;
  padding: 0.8rem 1rem;
  min-width: 0rem;
  min-height: 0 !important;
}

.increment-textbox .btn {
  color: #777;
  background: rgba(211, 211, 211, 0.2);
  padding: 0rem 1rem;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s linear;
  width: 1rem;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.increment-textbox .btn:first-child:hover {
  background-color: tomato;
  color: white;
}

.increment-textbox .btn:last-child:hover {
  background-color: rgb(14, 202, 86);
  color: white;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}