.tc-root{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
}

.header-container{
    margin-bottom: 1.2rem;
    margin-top: 1.2rem;
}

.header-container span{
  color: grey;
}
.header-container p{
 font-weight: bold;
 font-size: 2.4rem;
 margin-top: 10px;
}

.tc-body p{
text-align: justify;
margin-bottom: 1rem;
line-height: 1.8rem;
}

.tc-btn-container{
    display: flex;

}

.btn-item{
    padding: 15px 35px;
    border-radius: 8px;
    margin-right: 20px;
    margin-top: 20px;
    cursor: pointer;
}

.accept-item{
    color: white;
    background-color: #3f0f3f;
}
.accept-item:hover{
    color: white;
    background-color: var(--toast-success-color);
}