@import url("./../../shared/css/index.css");

.textbox {
  margin: 10px 0px;
  padding: 13px;
  font-size: 16px;
  border: solid 1px var(--app-theme-lighter);
  width: 100%;
  font-family: inherit;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.input {
  border-left-width: 5px;
}
.textarea {
  border-bottom-width: 5px;
  resize: none;
}
.textbox:focus {
  outline: 0;
}

.textbox::placeholder {
  color: var(--app-placeholder-grey);
}

.ghost-curtain {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: transparent;
  opacity: 0;
}

.auto-dropdown-children-container-full {
  position: absolute;
  top: 60px;
  left: 0px;
  z-index: 3;
  border-radius: 5px;
  width: 100%;
  background: white;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.auto-dropdown-item {
  cursor: pointer;
  padding: 15px 15px;
  color: black;
  text-align: left;
  text-transform: capitalize;
  overflow-wrap: break-word;
}
.auto-dropdown-item:hover {
  background: var(--app-theme-lighter);
  color: white;
  transition: 0.2s ease-out;
}

.auto-dropdown-item:active {
  background: var(--app-theme-hover);
}

.selected-item {
  background: var(--app-theme-lighter);
  color: white !important;
}

.selected-item:hover {
  background: antiquewhite !important;
  color: black !important;
  transition: 0.2s ease-out;
}
