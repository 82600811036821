#com-image-container img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  object-fit: cover;
  border: 3px solid var(--app-accent-pink);
}

#image-and-verify-container {
  display: flex;
  padding: 20px;
}

#company-name-container{
    margin-top: 10px;
}
#company-name-container span{
    font-size: 1.1rem;
    font-weight: bold;
    color: var(--app-theme);
    margin-left: 10px;
}
#invest-card-wrapper {
  border: 1px solid whitesmoke;
}
#badge-root-wrapper{
  margin-top: 10px;
  margin-bottom: 5px;
}
#learn-more-txt-container {
  margin-left: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
#learn-more-txt-container span{
  color: #0645AD ;
  cursor: pointer;
  font-weight: bold;
}

#name-and-desc-container,
#inest-money-container {
  padding-left: 15px;
  padding-right: 15px;
}
#name-and-desc-container h3 {
  margin-bottom: 15px;
  font-size: 1.1rem;
}
#name-and-desc-container p {
  font-size: 0.9rem;
  text-align: justify;
}


#inest-money-container {
  margin-top: 1.5rem;
}

#subscriptions-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}
#subscriptions-container div span, #notable-investors-container span, #return-percentages-container div span{
  color: grey;
  font-size: 0.8rem;
}
#notable-investors-container p {
    font-size: 0.9rem;
    font-weight: bold;
    margin-top: 8px;
}

#notable-investors-container{
    margin-bottom: 1.5rem;
}

#return-percentages-container{
    display: flex;
    justify-content: space-between;
}
#return-percentages-container div p{
    margin-top: 8px;
}



.invest-card-wrapper:hover {
  transform: scale(1.02);
  transition: 0.3s ease-out;
}

.invest-card-wrapper:active {
  transform: scale(1.01);
  transition: 0.2s ease-out;
}

#footer-btns-container{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
border-top: 1px solid whitesmoke;
}

#view-more-btn-container{
  display: flex;
  justify-content: center;
  align-items: center;
  padding:  20px;
  color: var(--app-theme);
  background-color: white;
  /* width: 30%; */
  cursor: pointer;
  /* border: 1px solid var(--app-theme); */
}
#view-more-btn-container svg{
  font-size: 1rem;
  margin-bottom: 5px;
  margin-left: 8px;
}
#view-more-btn-container:hover{
  /* background-color: var(--app-theme); */
  color:var(--app-accent-pink);

}

.inv-card-badge-container{
  padding: 10px 20px;
  background-color: rgb(235 255 235);
  color: #1d5213;
}
.inv-card-badge-container p{
  margin: 0;
  font-size: 0.8rem;
  text-align: center;
  font-weight: bold;
}

.pending{
  background-color: rgb(213 238 255);
  color: #0f4768 !important;
   
}
.pending p{
/* color: white !important; */
}
