.user-investment-items-wrapper {
  margin-top: 1rem;
}
.user-investment-items-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 15px;
  row-gap: 30px;
  margin-bottom: 2rem;
}
.investment-item-contain {
  margin-right: 20px;
  border-radius: 20px;
}
.investment-item-contain img {
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  width: 100%;
  height: 150px;
}
.investment-info-conatiner {
  padding: 2px 20px 20px 20px;
}
.investment-info-conatiner h3 {
  font-size: 1rem;
}

.opp-info-items-container {
  margin-top: 0.9rem;
  display: flex;
  justify-content: space-between;
}

.pps-container,
.shares-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pps-container span {
  color: var(--toast-success-color);
  font-weight: bold;
  font-size: 1.3rem;
  margin-left: 8px;
  margin-bottom: 8px;
}

.shares-container span {
  color: var(--app-theme);
  font-weight: bold;
  font-size: 1.3rem;
  margin-left: 8px;
  margin-bottom: 8px;
}


.investment-item-contain:hover {
  transform: scale(1.02);
  transition: 0.3s ease-out;
}

.investment-item-contain:active {
  transform: scale(1.01);
  transition: 0.2s ease-out;
}

#no-investments-container{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80vw;
  height: 70vh;
}


.response-btns{
    padding: 15px 35px;
    border-radius: 8px;
    margin-top: 20px;
    cursor: pointer;
    width: 26%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.1rem;
   
}
.response-btns svg{
  margin-right: 8px;
  margin-bottom: 5px;
}

.accept-item{
    color: white;
    background-color: var(--toast-success-color) !important;
}
.accept-item:hover{
    color: white;
    background-color: green !important;
}

.reject{
  border: 1px solid tomato;
  color: tomato;
}
.flex-me{
    display:flex;
    justify-content: space-between;
  }
.stats-root{
  margin-bottom: 3rem;
}
.money-section {
 position: absolute;
 top: -20px;
 right: 10px;
 }



 /* stats */
 .partnership-opp-name{
       display: flex;
    justify-content: center;
    align-items: center;
 }

 .opp-valuation h1{
font-size: 3rem;
color: var(--app-theme);
 }
 .investments-wrapper-root{
   width: 90%;
    margin: auto;
    margin-top: 2rem ;
 }

 .space-me-right{
  margin-right: 2rem;
}



 @media (max-width: 480px) {
 .user-investment-items-wrapper {
  grid-template-columns: repeat(1, 1fr);
  row-gap: 20px;
  margin-bottom: 2rem;
}
 .investments-wrapper-root{
   width: 95%;
 }
 .stats-root{
   display: block;
 }

 .partnership-opp-name{
   margin-bottom: 1.6rem;
   align-items: flex-start;
 }

 .opp-valuation h1, .right-of-title-box h1 {
    font-size: 1.7rem;
    color: var(--app-theme);
}

.vanish{
  display: none;
}

.right-of-title-box{
  padding-right: 0px;
  display: block;
}
 }