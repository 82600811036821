#asset-detail-wrapper {
  width: 90%;
  margin: auto;
  margin-top: 2rem;
}

#asset-img {
  height: 120px;
  width: 140px;
  border-radius: 10px;
  padding: 1px;
  object-fit: cover;
  border: 2px solid var(--app-accent-pink);;
}
#asset-info-txts-container {
  margin-left: 25px;
}

#asset-detail-container,
#asset-doc-container {
  margin-top: 2.4rem;
}
#asset-doc-container {
  margin-bottom: 2rem;
}

#asset-detail-container p {
  text-align: justify;
  margin-top: 10px;
  line-height: 1.5;
}

#asset-info-txts-container h2 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: var(--app-theme);
}
#asset-info-txts-container h2 span {
  font-size: 1.3rem;
  color: var(--app-theme);
  font-weight: normal;
}

.asset-id-number {
  font-size: 1.4rem;
  font-weight: bold;
  color: var(--app-theme);
}

.view-file-btn {
  padding: 10px 20px;
  color: black;
  background-color: white;
  border-radius: 30px;
  width: max-content;
  cursor: pointer;
}
.view-file-btn:hover {
  color: white;
  background-color: var(--app-accent-pink);
}


.img-and-title-container{
    display: flex;
}