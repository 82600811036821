:root {
  --toast-error-color: #ffe2e2;
  --toast-success-color: #259c23;
}

.toast-wrapper {
  position: fixed;
  bottom: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
}

.toast-container {
  /* position: fixed; */
  padding-right: 20px;
  color: white;
  /* max-width: 40%; */
  width: 60%;
  margin: auto;
  display: flex;
  color: grey;
  background-color: var(--toast-back-color);
  border-radius: 4px;
  border: solid 0px;
  border-left-width: 8px;
  border-left-color: var(--toast-border-color);
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  color: white;
  padding: 20px 20px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.success {
  background-color: #49c994;
}

.failed {
  background-color: tomato;
}
.icon {
  font-size: 1.5rem;
}

.message-container {
  display: flex;
  /* justify-content: center; */
  flex: 10;
  align-items: center;
  text-align: justify;

  /* margin-right: 2rem; */
  padding: 22px;
}

.message-container div {
  color: var(--toast-text-color);
}

.close-btn-container {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 5px 15px;
  margin-left: 2rem;
}

.close {
  display: none !important;
}

/* .right {
  right: 10px;
  top: 100px;
  animation: rightMove 0.5s ease-out 0s forwards;
}
.top {
  top: 0px;
  transform: translate(50%, 100%);
  animation: topMove 0.5s ease-out 0s forwards;
}
.bottom {
  bottom: 30px;
  transform: translate(50%, 10%);
  animation: bottomMove 0.5s ease-out 0s forwards;
}

@keyframes bottomMove {
  from {
    bottom: -60px;
  }
  to {
    bottom: 30px;
    transform: translate(50%, 10%);
  }
}
@keyframes topMove {
  from {
    top: -60px;
  }
  to {
    top: 0px;
    transform: translate(50%, 100%);
  }
}

@keyframes rightMove {
  from {
    top: 100px;
    right: -600px;
  }
  to {
    right: 10px;
    top: 100px;
  }
} */

.show-from-bottom {
  opacity: 0;
  /* animation-delay: 0.6; */
  animation: show-from-bottom .4s forwards;
}
@keyframes show-from-bottom {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
