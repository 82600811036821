.i-am-money {
  color: var(--toast-success-color) !important;
  font-weight: bold;
}

.all-pages-root-wrapper {
  margin: auto;
  margin-top: 1rem;
}
/* ======= end of Generic css ======== */
#user-dash-intro {
  display: flex;
  justify-content: space-between;
}
#intro-stats-container {
  width: 60%;
  display: flex;
  justify-content: flex-end;
}

#intro-txt-container {
  width: 40%;
}
#intro-txt-container p {
  font-size: 2rem;
  font-weight: bold;
}
#intro-txt-container span {
  font-size: 0.9rem;
  color: grey;
}

#intro-stats-container div  {
font-size: 2.5rem;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
}
#stat-item-container p {
  font-size: 3rem;
  font-weight: bold;
  color: var(--app-theme);
}
#stat-item-container span {
  font-size: 0.9rem;
  font-weight: bold;
  color: var(--app-theme);
  text-align: right !important;
}
.move-down-small {
      margin-right: 20px;
}

.profit-items {
  color: var(--toast-success-color) !important;
}
/* ============================ end of intro css ============= */

.dash-card-container {
  padding: 15px;
  border-radius: 20px;
  /* width: 95%; */
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
}

.dash-card-item {
  padding: 10px;
  display: flex;
}

.dash-card-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--app-accent-pink);
  border-radius: 10px;
  height: 55px;
  width: 55px;
  margin-right: 20px;
}
.dash-card-icon-container svg {
  color: white;
  font-size: 2rem;
}
.dash-card-txt-conatainer p {
  font-size: 1rem;
  color: grey;
}
.dash-card-txt-conatainer span {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--app-theme);
}

/* ========== end of cards css ================ */
.dashboard-investments-invites-container {
  margin-top: 2rem;
}
.inventments-lists {
  height: max-content;
  /* width: 95%; */
  margin-bottom: 15px;
}
.invitation-list {
  /* width: 95%; */
  height: max-content;
}
.dash-invites-container {
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 1rem;
}
.top-investors-container {
  padding: 20px;
  border-radius: 10px;
}

.dashboard-transaction-bar-container {
  margin-top: 1.5rem;
}
.right-of-title-box {
  flex-direction: column;
  align-items: flex-end;
  padding-right: 70px;
}

.right-of-title-box h6 {
  color: var(--app-money-green);
}

.right-of-title-box h1 {
  font-size: 3rem;
  color: var(--app-money-green);
}
.right-of-title-box h2 {
  color: var(--app-theme-success);
}

.accept-txt {
  padding: 10px 15px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.accept:hover {
  background-color: var(--app-theme-success);
  color: white;
}
.reject:hover {
  background-color: tomato;
  color: white;
}
.view:hover {
  background-color: var(--app-accent-pink);
  color: white;
}

#actions {
  display: flex;
}
.accept {
  margin-right: 7px;
}
.view {
  margin-right: 7px;
}

.set-payment-pref-container {
  position: relative;
  padding: 20px;
  /* width: 95%; */
  border-radius: 10px;
  margin-top: 30px;
}

.set-payment-close-btn {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 40px;
  width: 40px;
  cursor: pointer;
  color: tomato;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.set-payment-close-btn:hover {
  background-color: tomato;
  color: white;
}

#info-icon-container svg {
  margin-right: 15px;
  font-size: 2rem;
  color: maroon;
}

#notification-body-container span {
  color: maroon;
  font-weight: bold;
  font-size: 1.2rem;
}
#notification-body-container p {
  margin-top: 5px;
}
#notification-body-container p a{
  text-decoration: none;
  font-weight: bold;
}


@media (max-width: 480px) {
  #intro-txt-container p {
  font-size: 1rem;
}
.dash-card-icon-container {
    height: 45px;
    width: 45px;
    margin-right: 5px;
}
.dash-card-icon-container svg {
  font-size: 1rem;
}
.dash-card-txt-conatainer span {
    font-size: 1rem;
}
.dash-card-container {
    width: 100%;
    display: block;
    padding: 0;
}
.mb{
  margin-bottom: 10px;
}

#intro-stats-container div svg {
    display: none;
    
}
#intro-stats-container, #user-dash-intro {
    display: block;
}
#intro-stats-container {
    width: 100%;
    position: relative;
}
#stat-item-container p {
    font-size: 1.4rem;
}
#intro-txt-container {
    width: 100%;
}
.dash-card-item {
border-radius: 10px;
}
#intro-stats-container div {
    font-size: 1.5rem;
    display: block;
    margin-bottom: 20px;
}
}