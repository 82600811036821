.response-wrapper{
    padding: 20px 40px;
   border-top-right-radius: 0px;
   border-top-left-radius: 60px;
   border-bottom-right-radius: 60px;
   border-bottom-left-radius: 0px;
}


#response-btns-container{
    display: flex;
    justify-content: space-evenly;
}

#response-intro-container{
    margin-top: 1.6rem;
    margin-bottom: 2rem;
}

#res-about-container{
      margin-bottom: 3rem;
}
#res-about-container p{
     text-align: justify;
}
#res-name-section{
    margin-top: 2rem;
}