@import url("https://fonts.googleapis.com/css?family=Google+Sans:200,300,400,400i,500,600,700,900");
@import url("./elevation.css");
@import url("./mobile-only.css");
@import url("./pc-only.css");
@import url("./anime.css");
:root {
  --default-font-family: "Google Sans", sans-serif;
}

html {
  font-family: var(--default-font-family);
}
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100vh;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
.put-me-inline {
  display: inline-block;
}

.everyday-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.text-toggler {
  color: var(--app-theme-success) !important;
  margin-left: 6px;
  cursor: pointer;
}

.text-toggler:hover {
  color: var(--app-accent-pink) !important;
  transition: 0.2s ease-out;
}

.round-action-btns {
  background-color: var(--action-color);
  margin-left: 15px;
}
