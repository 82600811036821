:root {
  --elevate-float: 0 1px 0px 0 rgba(0, 0, 0, -1.84),
    0 2px 10px 0 rgba(0, 0, 0, 0.1);
  --elevate-half: 0 1px 0px 0 rgb(0 0 0 / 0%), 0 2px 4px 0 rgb(0 0 0 / 3%);
  --elevate-1: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  --elevate-1-half: 0 5px 11px 0 rgba(0, 0, 0, 0.18),
    0 4px 15px 0 rgba(0, 0, 0, 0.15);
  --elevate-2: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  --elevate-3: 0 12px 15px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
  --elevate-4: 0 16px 28px 0 rgba(0, 0, 0, 0.22),
    0 25px 55px 0 rgba(0, 0, 0, 0.21);
  --elevate-5: 0 27px 24px 0 rgba(0, 0, 0, 0.2),
    0 40px 77px 0 rgba(0, 0, 0, 0.22);

  --elevate-float-2: 0px 4px 42px -12px rgba(0, 0, 0, 0.09);
}
.elevate-float {
  box-shadow: var(--elevate-float);
  -webkit-box-shadow: var(--elevate-float);
}
.elevate-half {
  box-shadow: var(--elevate-half);
  -webkit-box-shadow: var(--elevate-half);
}
.elevate-1 {
  -webkit-box-shadow: var(--elevate-1);
  box-shadow: var(--elevate-1);
}
.elevate-1-half {
  -webkit-box-shadow: var(--elevate-1-half);
  box-shadow: var(--elevate-1-half);
}
.elevate-2 {
  -webkit-box-shadow: var(--elevate-2);
  box-shadow: var(--elevate-2);
}

.elevate-3 {
  -webkit-box-shadow: var(--elevate-3);
  box-shadow: var(--elevate-3);
}

.elevate-4 {
  -webkit-box-shadow: var(--elevate-4);
  box-shadow: var(--elevate-4);
}

.elevate-5 {
  -webkit-box-shadow: var(--elevate-5);
  box-shadow: var(--elevate-5);
}
.elevate-float-2 {
  -webkit-box-shadow: var(--elevate-float-2);
  box-shadow: var(--elevate-float-2);
}
